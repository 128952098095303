const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_signup_signin',
    forgotPassword: 'B2C_1A_PasswordReset',
    editProfile: 'B2C_1A_ProfileEdit',
  },
  authorities: {
    signUpSignIn: {
      // authority: 'https://checkinbuddySA.b2clogin.com/checkinbuddySA.onmicrosoft.com/B2C_1_signupsignincib',
      authority: 'https://checkinbuddySA.b2clogin.com/checkinbuddySA.onmicrosoft.com/B2C_1A_signup_signin',
      scopes: ['openid'],
    },
    forgotPassword: {
      authority: 'https://checkinbuddySA.b2clogin.com/checkinbuddySA.onmicrosoft.com/B2C_1_reset_password',
      scopes: ['openid'],
    },
    editProfile: {
      authority: 'https://checkinbuddySA.b2clogin.com/checkinbuddySA.onmicrosoft.com/B2C_1A_ProfileEdit',
      scopes: ['openid'],
    },
    signUpInvitation: {
      authority: 'https://checkinbuddySA.b2clogin.com/checkinbuddySA.onmicrosoft.com/B2C_1A_INV_genlink',
      scopes: ['openid'],
    },
    redeemInvitation: {
      authority: `https://checkinbuddySA.b2clogin.com/checkinbuddySA.onmicrosoft.com/${
        process.env.VUE_APP_MODE === 'staging' ? 'B2C_1A_Dev-Signup_Invitation' : 'B2C_1A_signup_invitation'
      }`,
      scopes: ['openid'],
    },
  },
  authorityDomain: 'checkinbuddySA.b2clogin.com',
};
export default b2cPolicies;
