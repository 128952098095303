










import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { guestModule } from '../../../../store/modules/guest.module';

@Component
export default class FormulateObjectExtraInfo extends Vue {
  public $refs: Vue['$refs'] & {
    objectFormExtraInfo: { isValid: boolean };
  };

  @Prop() context: Record<any, any>;

  data: any = {};
  validation: any = {};

  get model() {
    return this.data;
  }

  set model(payload) {
    Object.assign(this.context.model, payload);
    this.data = payload;
  }

  created() {
    if (this.context.model === '') {
      this.context.model = {};
    }
    Object.assign(this.data, this.context.model);
  }
  mounted() {
    this.$watch(
      () => {
        return this.$refs.objectFormExtraInfo.isValid;
      },
      (val) => {
        guestModule.setExtraInfoFormIsValid(val);
      },
    );
  }
}
