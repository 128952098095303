import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { get } from '@/services/app-storage';
import store from '@/store';
import client from '@/shared/plugins/content';
import { Entry } from 'contentful';

@Module({
  dynamic: true,
  store,
  name: 'content',
  namespaced: true,
  preserveState: false,
})
class ContentModule extends VuexModule {
  subContent = [];

  countryInfo = get('country_info') || null;

  alertBannerCnt = 'Placeholder for: Alert Banner';

  content: { [key: string]: any } = {};

  get countryCode() {
    return this.countryInfo?.callingCodes[0] || null;
  }

  @Mutation
  setSub(extra: any) {
    this.subContent = extra;
  }

  @Action
  async getContent() {
    try {
      const response = await client.getEntries();
      response.items.forEach((item) => {
        this.content[item.sys.contentType.sys.id] = item.fields;
      });

      return response.items;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  @Action
  getSubContent() {
    client
      .getEntries({
        content_type: 'subscriptionModel',
      })
      .then((response: { items: any[] }) => {
        const sorted = response.items.sort((a: any, b: any) => a.fields.name.localeCompare(b.fields.name)).reverse();

        this.setSub(sorted);
        return sorted;
      });
  }

  @Action
  geolocation(_slatLong: { lat: any; lng: any }) {
    // if (isEmpty(this.countryInfo))
    // this.geocoder.geocode({ location: latLong }, async (res: any, status: any) => {
    //   if (status === 'OK') {
    //     const add = getAddressCountry(res);
    //     const country = getAddressCountry(add[0].address_components);
    //
    //     const req = await fetch(`https://restcountries.eu/rest/v2/name/${country[0].long_name}?fullText=true`);
    //     const body = await req.json();
    //     localStorage.setItem('country_info', JSON.stringify(body[0]));
    //
    //   }
    // });
  }
}

export default getModule(ContentModule, store);
