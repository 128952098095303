<script>
import { appModule } from '@/store/modules/app.module';
export default {
  name: 'BookingVTour',
  data() {
    return {
      steps: [
        /*    {
          target: '[data-v-step="1"]',
          header: {
            title: 'You can <span style="font-weight:700">create a new booking</span>',
          },
          content: 'by selecting this button and entering the bookings information into the relevant fields.',
          params: {
            placement: 'top-end',
            enableScrolling: false,
          },
        }, */
      ],
      myCallbacks: {
        onPreviousStep: this.prevStepCallback,
        onNextStep: this.nextStepCallback,
        onSkip: this.onSkip,
        onFinish: this.onFinish,
      },
    };
  },
  mounted() {
    const BookingVTourSee = JSON.parse(window.localStorage.getItem('BookingVTourSee'));
    if (BookingVTourSee == null || BookingVTourSee) {
      setTimeout(() => {
        try {
          this.$tours.NavVTour.start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    prevStepCallback(currentStep) {
      if (currentStep === 2) {
        /*         console.log(currentStep);
         */
      }
    },
    nextStepCallback(currentStep) {
      if (currentStep === 1) {
        /*         console.log(currentStep);
         */
      }
    },
    onSkip(currentStep) {
      appModule.updateTourActive(false);
      window.localStorage.setItem('NavVTourSee', false);
      window.localStorage.setItem('BookingVTourSee', false);
      window.localStorage.setItem('AccommodationVTourSee', false);
    },
    onFinish() {
      // appModule.updateTourActive(false);
      window.localStorage.setItem('BookingVTourSee', false);
    },
  },
};
</script>
<template>
  <v-tour class="BookingVTour" name="BookingVTour" :steps="steps" :options="{ highlight: false }" :callbacks="myCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header {
  background: #ea5455 !important;
}
.BookingVTour .v-step__arrow {
  left: 75% !important;
  border-color: #ea5455 !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
</style>
