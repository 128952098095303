import { GuestAnswers } from './guest.dto';

export default class MinorDto {
  id?: string;
  firstName!: string;
  lastName!: string;
  nationality?: string;
  passport?: string;
  rsaId?: string;
  dateOfBirth!: string;
  healthCheck?: string;
  guestId?: string;
  guestAnswers: GuestAnswers[];
  requirements!: string;
}
