/* eslint-disable no-param-reassign */
/* eslint-disable no-inner-declarations */
import Vue from 'vue';

Vue.directive('expand', {
  inserted(el, binding) {
    if (binding.value !== null) {
      function calcHeight() {
        const currentState = el.getAttribute('aria-expanded');
        el.classList.add('u-no-transition');
        el.removeAttribute('aria-expanded');
        el.style.height = null || '';
        el.style.height = `${el.clientHeight + 15}px`;
        el.setAttribute('aria-expanded', currentState as string);
        setTimeout(function () {
          el.classList.remove('u-no-transition');
        });
      }
      el.classList.add('expand');
      el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
      calcHeight();
      window.addEventListener('resize', calcHeight);
    }
  },
  update(el, binding) {
    if (el.style.height && binding.value !== null) {
      el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
    }
  },
});
