export const colors = {
  primary: '#EA5455',
  secondary: '#008489',
  success: '#66999b',
  danger: '#EA5455',
  warning: '#FF9F43',
  dark: '#1E1E1E',
};

// // CONFIGS
// // eslint-disable-next-line no-undef
const themeConfig = {
  disableCustomizer: true,
  disableThemeTour: true,
  footerType: 'static',
  hideScrollToTop: false,
  mainLayoutType: 'horizontal',
  navbarColor: '#fff',
  routerTransition: 'zoom-fade',
  rtl: false,
  sidebarCollapsed: false,
  theme: 'light',
  userInfoLocalStorageKey: 'userInfo',
  errorLog: ['production'],
};
export default themeConfig;
