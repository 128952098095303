import { IBookingDto } from '@/app/entities/tenant/IBookingDto';
import * as _ from 'lodash';

/* eslint-disable camelcase */
export class IAddressDto {
  id?: string;
  name: string;
  formatted_address: string;
  formatted_phone_number?: any;
  latitude?: number;
  longitude?: number;
  url: string;
  international_phone_number?: any;
  geometry?: { location: { lat: () => number; lng: () => number } };
}

export class AccommodationDtoDocuments {
  name: string;
  type: string;
  url: string;
  fileName: string;
  attachEmail: string;

  constructor(args: Record<string, any>) {
    Object.assign(this, _.omit(args, 'dtoClass'));
    this.url = args.file[0].url;
    this.fileName = args.file[0].name;
  }
}

export interface IIndemnity {
  name: string;
  form: string;
}

export class GuestConfirmation {
  confirmationSchema: string[];
}

export class AccommodationDto {
  id!: string | undefined;
  name!: string;
  email!: string;
  phone!: any;
  contactPerson!: string;
  healthOfficer!: string;
  emergencyNumber!: any;
  checkInTimeStart!: string;
  checkInTimeEnd!: string;
  checkOutTime!: string;
  healthDisclaimer!: any;
  latitude!: number;
  longitude!: number;
  indemnity!: IIndemnity[];
  directions!: { url: any; name: any };
  qrCode!: string;
  logo!: any;
  rooms!: number;
  address: IAddressDto = new IAddressDto();
  accommodationDocuments: AccommodationDtoDocuments[] = [];
  media!: any[];
  healthQuestions: any[];
  questionsRequired: boolean;
  guestConfirmation: GuestConfirmation = new GuestConfirmation();
  file!: any;
  booking!: IBookingDto[];
  [key: string]: any;
}
