/* eslint-disable no-param-reassign */
/* eslint-disable new-cap */
import { mixin } from './mixin';
import { MSAL } from './src/main';
import { iMSAL, Options } from './src/types';

export default class msalPlugin {
  static install(Vue: any, options: Options): void {
    Vue.prototype.$msal = new msalPlugin(options, Vue);
  }

  constructor(options: Options, Vue: any = undefined) {
    const msal = new MSAL(options);
    if (Vue && options.framework && options.framework.globalMixin) {
      Vue.mixin(mixin);
    }
    const exposed: iMSAL = {
      data: msal.data,
      async signIn(options?: any) {
        return await msal.signIn(options);
      },
      async signOut() {
        return msal.signOut();
      },
      async acquireToken() {
        return await msal.acquireToken();
      },
      async isAuthenticated() {
        return msal.isAuthenticated();
      },
      async getInviteToken(tokenRequest) {
        return msal.getInviteToken(tokenRequest);
      },
      async memberTokenSignUp(token: string) {
        return msal.memberTokenSignUp(token);
      },
      async memberPasswordRedirect() {
        return msal.memberPasswordRedirect();
      },
    };
    return exposed;
  }
}
