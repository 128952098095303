import store from '@/store';
import { AuthenticationResult } from '@azure/msal-browser';
import * as Sentry from '@sentry/vue';
import { getModule, Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import { UserDto } from '../../app/models/user/user.dto';
import router from '../../router';
import service from '../../services/app-http-client';

interface IAuthModuleState {
  isAuthenticated: boolean;
  token: string;
}

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
export class AuthModule extends VuexModule implements IAuthModuleState {
  isAuthenticated = false;

  token = '';

  @MutationAction({ mutate: ['token', 'isAuthenticated'] })
  public async setToken(token: string) {
    return {
      token,
      isAuthenticated: true,
    };
  }

  @MutationAction({ mutate: ['isAuthenticated', 'token'] })
  public async login(authResponse: AuthenticationResult): Promise<any> {
    try {
      const response = await service.get<UserDto>('auth', {
        headers: {
          Authorization: `Bearer ${authResponse.accessToken}`,
        },
      });

      const user = response.data;

      store.commit('user/SET_USER', user, { root: true });
      if (user?.email) {
        Sentry.setUser({ email: user.email });
      }
      return {
        isAuthenticated: true,
        token: authResponse.idToken,
      };
    } catch (error: any) {
      throw new Error(error);
    }
  }
}

export const Auth: AuthModule = getModule(AuthModule, store);
