<template lang="html">
  <div class="vs-component vs-divider">
    <span :class="borderClass" :style="afterStyle" class="vs-divider-border after" />
    <span
      v-if="icon || $slots.default"
      :style="{
        color: textColor,
        background: backgroundColor,
      }"
      :class="textAndBackgroundClass"
      class="vs-divider--text"
    >
      <template v-if="!icon">
        <slot />
      </template>

      <vs-icon v-else :icon-pack="iconPack" :icon="icon" class="icon-divider notranslate vs-divider--icon" />
    </span>
    <span :style="beforeStyle" :class="borderClass" class="vs-divider-border before" />
  </div>
</template>

<script>
import _color from '@/assets/utils/color';

export default {
  name: 'VsDivider',
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0,.1)',
    },
    background: {
      type: String,
      default: 'transparent',
    },
    icon: {
      default: null,
      type: String,
    },
    borderStyle: {
      default: 'solid',
      type: String,
    },
    borderHeight: {
      default: '1px',
      type: String,
    },
    position: {
      default: 'center',
      type: String,
    },
    iconPack: {
      default: 'material-icons',
      type: String,
    },
  },
  computed: {
    getWidthAfter() {
      let widthx = '100%';
      if (this.position === 'left') {
        widthx = '0%';
      } else if (this.position === 'left-center') {
        widthx = '25%';
      } else if (this.position === 'right-center') {
        widthx = '75%';
      } else if (this.position === 'right') {
        widthx = '100%';
      }
      return widthx;
    },
    getWidthBefore() {
      let widthx = '100%';
      if (this.position === 'left') {
        widthx = '100%';
      } else if (this.position === 'left-center') {
        widthx = '75%';
      } else if (this.position === 'right-center') {
        widthx = '25%';
      } else if (this.position === 'right') {
        widthx = '0%';
      }
      return widthx;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    borderColor() {
      return _color.getColor(this.color);
    },
    afterStyle() {
      const classes = {
        width: this.getWidthAfter,
        'border-top-width': this.borderHeight,
        'border-top-style': this.borderStyle,
      };
      if (!_color.isColor(this.color)) {
        classes['border-top-color'] = this.borderColor;
      }
      return classes;
    },
    beforeStyle() {
      const classes = {
        width: this.getWidthBefore,
        'border-top-width': this.borderHeight,
        'border-top-style': this.borderStyle,
      };
      if (!_color.isColor(this.color)) {
        classes['border-top-color'] = this.borderColor;
      }
      return classes;
    },
    borderClass() {
      const classes = {};
      const borderColor = _color.isColor(this.color) ? this.color : 'default';
      classes[`vs-divider-border-${borderColor}`] = true;
      return classes;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    textColor() {
      return _color.getColor(this.color !== 'rgba(0, 0, 0,.1)' ? this.color : null);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    backgroundColor() {
      return _color.getColor(this.background);
    },
    textAndBackgroundClass() {
      const classes = {};

      const textColor = _color.isColor(this.color) ? this.color : 'default';
      classes[`vs-divider-text-${textColor}`] = true;

      const backgroundColor = _color.isColor(this.background) ? this.background : 'default';
      classes[`vs-divider-background-${backgroundColor}`] = true;

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-divider {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  clear: both;
  width: 100%;
  margin: 15px 0;
  .after,
  .before {
    position: relative;
    display: block;
    width: 100%;
  }
}
.vs-divider--text {
  position: relative;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 0.9375em;
  white-space: nowrap;
  background: rgb(255, 255, 255);
  cursor: default;
  user-select: none;
}

.vs-divider--icon {
  font-size: 1.25em;
}
</style>
