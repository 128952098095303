export async function set(data: unknown, key: any): Promise<void> {
  await Promise.resolve();
  window.localStorage.setItem(key, JSON.stringify(data));
}

export function get(key: string): any {
  const data = window.localStorage.getItem(key) || null;
  if (!data || data === 'undefined') {
    return '';
  }
  return JSON.parse(data);
}

export function getAddressCountry(addressComponent: any[]) {
  return addressComponent.filter((add: any) => {
    return add.types.includes('country');
  });
}
