import Vue from 'vue';
import Router from 'vue-router';
import routes from 'vue-auto-routing';
import { createRouterLayout } from 'vue-router-layout';
import Meta from 'vue-meta';

Vue.use(Router);
Vue.use(Meta);

const RouterLayout = createRouterLayout((layout: any) => {
  return import(`./layouts/${layout}.vue`);
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes,
    },
  ],
});
