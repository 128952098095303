import App from '@/App.vue';
import loader from '@/app/components/loader';
import '@/app/utils/filters';
// Icons
import '@/assets/css/iconfont.css';
// Tailwind
import '@/assets/css/main.css';
import '@/plugins';
import Events from '@/plugins/event-bus';
import EventBus from '@/plugins/event-bus/notification/app.events';
import '@/plugins/event-bus/route.subscriber';
import '@/registerServiceWorker';
import '@/shared';
import { i18n } from '@/shared/plugins/vue-i18n';
import store from '@/store';
import contentModule from '@/store/modules/content.module';
import '@/styles/index.scss';
import localforage from 'localforage';
import 'material-icons/iconfont/material-icons.css';
import 'reflect-metadata';
import VTooltip from 'v-tooltip';
import Vlf from 'vlf';
import Vue from 'vue';
import router from './permissions';
import { version } from '../package.json';

Object.defineProperty(Array.prototype, 'first', {
  value() {
    return this.find(Boolean);
  },
});

Vue.prototype.$version = version;
Vue.use(Vlf, localforage);
Vue.use(EventBus);
Vue.use(Events);
Vue.use(loader);
Vue.use(VTooltip);

Vue.config.productionTip = process.env.NODE_ENV === 'production';
export const app = new Vue({
  i18n,
  router,
  store,
  el: '#app',
  async created() {
    this.$nextTick(() => {
      contentModule.getContent().then(() => {
        document.dispatchEvent(new Event('x-app-rendered'));
      });
    });
  },
  render: (h) => h(App),
}).$mount('#app', true);
