/* eslint-disable no-param-reassign */

import loading from './loader';

export default {
  install(Vue: any) {
    const vsFunctions = {
      loading,
    };
    Vue.prototype.$vs = vsFunctions;
  },
};
