import Vue from 'vue';
import dayjs from './date';

Vue.filter('title', (value: string, replacer = '_') => {
  if (!value) return '';
  const newVal = value.toString();

  const arr = newVal.split(replacer);
  const capitalizedArray: any[] = [];
  arr.forEach((word: string) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(' ');
});

Vue.filter('month', (val: string, showYear = true) => {
  const regex = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regex?.exec(val)?.[1];
  }

  return `${regex?.exec(val)?.[1]} ${regex?.exec(val)?.[1]}`;
});

Vue.filter('formatDate', (value: any) => {
  return dayjs(value, 'MM/DD/YYYY');
});

Vue.filter('time', function (value: any) {
  return dayjs(value).format('HH:mm:ss');
});

Vue.filter('date', function (value: string) {
  const val = String(value);
  const date = val.slice(8, 10).trim();
  const month = val.slice(4, 7).trim();
  const year = val.slice(11, 15);
  return dayjs(value).format('DD MMMM YYYY');

  // return `${date} ${month} ${year}`;
});
Vue.filter('shortDate', function (value: string) {
  const val = String(value);
  if (val === 'Invalid Date') {
    return '';
  }
  return dayjs(value).format('DD MMM YYYY');

  // return `${date} ${month} ${year}`;
});
Vue.filter('invalidCheck', function (value: string) {
  const val = String(value);
  if (val === 'Invalid Date') {
    return '';
  } else {
    return value;
  }

  // return `${date} ${month} ${year}`;
});
