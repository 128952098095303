
import Vue from 'vue';
import { Component, Emit, Prop, PropSync, Watch } from 'vue-property-decorator';

interface selectOptions {
  value: string;
  name: string;
}

@Component
export default class extends Vue {
  @Prop() options: selectOptions;

  @PropSync('value') valueSync: string;

  show = false;

  selected = '';

  clickOutside(): void {
    this.show = false;
  }

  @Watch('valueSync')
  selectNone() {
    if (this.valueSync == '') {
      this.selected = '';
    }
  }

  @Emit()
  setSelected(option: selectOptions): void {
    this.valueSync = option.value;
    this.selected = option.name;
    this.show = false;
  }
}
