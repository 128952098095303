





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import { Notifications } from '@/store/modules/notification.module';

@Component
export default class ErrorModalComponent extends Vue {
  @PropSync('active') activeSync!: boolean;

  public title = 'Error';

  public closeText: any = 'close';

  get errorDescription() {
    return Notifications.errorMessage;
  }
}
