/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-lonely-if */
import NProgress from 'nprogress';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { canNavigate } from './plugins/casl/role-guard';
import router from './router';
import store from './store';

sync(store, router, { moduleName: 'RouteModule' });

router.beforeEach(async (to, from, next) => {
  /*   console.log(Vue.prototype.$ability);
   */ // First load
  if (from.name === null && to.meta?.requiresAuth) {
    // Try retrieving a fresh access token on first load
    /*     console.log(to);
     */ const isAuthed = await Vue.prototype.$msal.isAuthenticated();

    if (isAuthed) return next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    NProgress.start();
    if (!(await Vue.prototype.$msal.isAuthenticated())) {
      // Vue.prototype.$msal.signIn({ loginHint: 'nick@handycats.com' });

      NProgress.set(100 / to.matched.length);
      return next({
        path: '/',
      });
    }

    if (!canNavigate(to)) {
      // If logged in => not authorized
      return next({
        path: '/unauthorized',
      });
    }
  }

  return next();
});

router.afterEach(async (_to, _from) => {
  NProgress.done();
});

export default router;
