/* ============
 * English Language File
 * ============
 */
import shared from './shared.json';
import admin from './admin.json';
import app from './app.json';
import marketing from './marketing.json';
import account from './account.json';
import settings from './settings.json';
import models from './models.json';
import netcoresaas from './netcoresaas.json';
import tour from './tour.json';
import content from './content.json';

export default {
  shared,
  admin,
  app,
  marketing,
  account,
  settings,
  models,
  netcoresaas,
  tour,
  content,
};
