
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueTimepicker from '@/app/components/forms/timepicker/VueTimepickerPlus.vue';

@Component({
  components: { VueTimepicker },
})
export default class Timepicker extends Vue {
  @Prop() context: Record<string, any>;

  log(val: any) {
    this.context.model = val;
    /*     console.log(val);
     */
  }
}
