/* eslint-disable camelcase */
import { ITenantDto } from '@/app/models/tenant/ITenantDto';

export interface IUserBookingDto {
  id: number;
  tenantId: string;
  bookingId: string;
  isActive: boolean;
}

export interface IProfileDto {
  id: number;
  nationality: string;
  passportId: string;
  dateOfBirth?: any;
  emergencyContact: any;
  medicalAidNumber: string;
  medicalAidProvider: string;
  medicalAidEmergency: any;
  specialRequirements: any;
  medicalConditions: string;
  vaccineUpToDate: string;
  vaccinated: string;
  allergies: string;
  travelInsurance: string;
  insuranceProvider: string;
  dietaryRequirements: string;
  insuranceNumber: string;
  userId: string;
  bookings: IUserBookingDto[];
  vaccinationProof: any;
}

export interface ISettingsDto {
  id: string;
  emailNotifications: number;
  smsNotifications: number;
  pushNotifications: number;
}

export interface INotificationDto {
  id: number;
  title: string;
  description: string;
  is_read: boolean;
  createdAt: Date;
}
export class UserDto {
  [key: string]: any;
  id!: string;
  active!: boolean;
  data!: any;
  firstName!: string;
  fullName!: string;
  lastName!: string;
  oid!: string;
  email!: string;
  profile!: IProfileDto;
  settings!: Partial<ISettingsDto>;
  member!: any[];
}
