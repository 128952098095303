/* eslint-disable @typescript-eslint/no-var-requires */
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import store from '@/store';
class VueSocketIo {
  public init(vue: any): void {
    const socket = io(process.env.VUE_APP_API_URL as string, {
      autoConnect: false,
      transports: ['websocket'],
    });

    vue.use(VueSocketIOExt, socket, { store });
  }
}

export default new VueSocketIo();
