import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import Vue from 'vue';
import emitter from '../emitter';
import { IMessage } from './notification.constants';

const eventBus = (): void => {
  function notify(props: Record<string, any>) {
    Vue.prototype.$toast(
      {
        component: ToastificationContent,
        props,
      },
      {
        timeout: 8000,
      },
    );
  }

  emitter.on<IMessage>('toast', (event) => {
    if (event)
      notify({
        title: event.title,
        icon: event.icon,
        text: event.text,
        variant: event.color,
      });
  });

  emitter.on('error', (event) => {
    notify({
      title: 'Woops something went wrong!',
      icon: 'concierge-bell',
      text: event.text,
      variant: 'danger',
    });
  });
};

export default eventBus;
