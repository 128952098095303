/* eslint-disable @typescript-eslint/no-var-requires */
import { createClient } from 'contentful';

const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: 'cspmv8yfetk3',
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: '4ZcK3uSjOb1tK1X9TRSb_Vf40faOoW5o_1hQ6T9UU_E',
  environment: 'production',
});

export default client;
