
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, VModel, Emit, Watch } from 'vue-property-decorator';
import { guestModule } from '../../../store/modules/guest.module';

@Component({})
export default class Dialog extends Vue {
  @VModel({ type: Boolean }) isOpen!: boolean;

  @Prop() lgScreen!: boolean;

  @Prop() pricingScreen!: boolean;

  @Prop() qrScreen!: boolean;

  @Prop() receiptScreen!: boolean;

  @Prop() teamsScreen!: boolean;

  @Prop() littleScreen!: boolean;

  @Prop() closeBlack: boolean;

  @Prop() closeWhite: boolean;

  @Prop() closeRed: boolean;

  @Emit('clear')
  clear() {
    return true;
  }

  closeDialog() {
    if (guestModule.editingGuestFlag === true)
      return this.$swal({
        title: 'Unsaved changes',
        text: 'Would you like go back and save your changes before closing?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then((result) => {
          if (result.dismiss) {
            guestModule.setEditingFlag(false);
            this.isOpen = false;
          }
          return;
        })
        .then();
    this.isOpen = false;
  }
}
