export const AUTH_ERROR = {
  title: 'Woops something went wrong!',
  icon: 'concierge-bell',
  text: 'Sorry we had trouble signing you in, please try again',
  variant: 'danger',
};

export const AUTH_SUCCESS = {
  title: 'Authentication successful!',
  icon: 'concierge-bell',
  text: 'Welcome back to Check-in buddy',
  variant: 'success',
};

export const SUBSCRIPTION_RENEWED = {
  title: 'Payment received!',
  icon: 'file-invoice',
  text: 'Your Subscription has been renewed  ',
  variant: 'primary',
};

export const USER_NOT_REGISTERED = {
  title: 'Woops something went wrong!',
  icon: 'concierge-bell',
  text: 'No organisation found for account, please register to create a new organisation',
  variant: 'danger',
};
export type color = 'danger' | 'warning' | 'success' | 'dark' | 'secondary' | 'primary';

export interface IMessage {
  title: string;
  text: string;
  icon: string;
  color: color;
  createdAt: string;
}
