import { app } from '@/main';
import NProgress from 'nprogress';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { Notifications } from '../store/modules/notification.module';
import Vue from 'vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import isEmpty from '../app/utils/object.utils';

const isDev = process.env.NODE_ENVl;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (isDev) NProgress.start();

  if (!isEmpty(Vue.prototype.$msal.data.accessToken) && isEmpty(config.headers?.Authorization))
    config.headers = {
      Authorization: `Bearer ${Vue.prototype.$msal.data.accessToken}`,
    };
  if (tenantModule.apiKey && config.headers) {
    config.headers['x-organisation-id'] = `${tenantModule.apiKey}`;
  }

  // eslint-disable-next-line no-param-reassign
  config = {
    showNotify: false,
    ...(config ?? {}), // preserve a given request.config object
    start: Date.now(),
  };

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  if (isDev)
    if (response.config.showNotify && response.config.notify) {
      app.$toast(
        {
          component: ToastificationContent,
          props: {
            title: response.config.notify.title,
            text: response.config.notify.text ?? null,
            icon: 'CheckIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-right' as any,
        },
      );
    }

  NProgress.done();
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const originalRequest = error.config;

  if (error.response?.status !== 401 || originalRequest.retry) {
    if (error.response?.data.message.includes('duplicate key value')) {
      Notifications.modalError({ isActive: true, errorMessage: error.response?.data.message });
      app.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Duplicate Booking ID',
            text: 'This booking ID has been used, please enter a new one.',
            icon: 'exclamation-circle',
            variant: 'danger',
          },
        },
        {
          position: 'top-right' as any,
        },
      );
    } else {
      Notifications.modalError({ isActive: true, errorMessage: error.response?.data.message });
      app.$toast(
        {
          component: ToastificationContent,
          props: {
            title: error.response?.status.toString() ?? 'Oops! Something went wrong',
            text:
              error.response?.data.message ??
              'Internal server error, if the problem persists please contact us via the support button in the bottom right corner of the page.',
            icon: 'exclamation-circle',
            variant: 'danger',
          },
        },
        {
          position: 'top-right' as any,
        },
      );
    }
    return Promise.reject(error);
  }

  originalRequest.retry = true;

  // Try request again with new token
  try {
    return Vue.prototype.$msal.acquireToken().then(async (res: any) => {
      if (res instanceof Object) {
        originalRequest.headers = {
          Authorization: `Bearer ${Vue.prototype.$msal.data.accessToken}`,
        };
        originalRequest.headers['x-organisation-id'] = `${tenantModule.apiKey}`;
        NProgress.done();

        return axios.request(originalRequest);
      }
      return new Error('Unauthorized');
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
