import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import Vue from 'vue';
import { IMessage } from '../../plugins/event-bus/notification/notification.constants';
import dayjs from 'dayjs';
import service from '@/services/app-http-client';

@Module({ dynamic: true, store, name: 'notification', namespaced: true, preserveState: false })
export class NotificationModule extends VuexModule {
  messages = [] as IMessage[];

  showError = false;

  errorMessage = '';

  get orderedMessages() {
    return this.messages.sort((a, b) => {
      const dateA = dayjs(new Date(a.createdAt));
      const dateB = dayjs(new Date(b.createdAt));
      return dateA.isBefore(dateB) ? 1 : -1;
    });
  }

  @Mutation
  NEW_MESSAGE(message: IMessage) {
    this.messages.push(message);
  }

  @Action
  socket_message(message: IMessage) {
    Vue.prototype.$emitter.emit('toast', message);
    this.NEW_MESSAGE(message);
  }

  // @Action
  // socket_booking(guest: Guest) {
  //   // Vue.prototype.$emitter.emit('toast', message);
  //   // this.NEW_MESSAGE(message);
  //   console.log(guest);
  // }

  @MutationAction({ mutate: ['messages'] })
  async setMessage(payload: any) {
    const messages = payload.map((v: any) => v.message);
    return {
      messages: messages,
    };
  }

  @MutationAction({ mutate: ['showError', 'errorMessage'] })
  async modalError(payload: { isActive: boolean; errorMessage: string }): Promise<{
    showError: boolean;
    errorMessage: string;
  }> {
    return {
      showError: payload.isActive,
      errorMessage: payload.errorMessage,
    };
  }
  @Action
  async deleteNotifications(id: string) {
    const res = await service.delete(`/notification/clear/${id}`);

    if (res.data) {
      /*       this.commitMember(res.data);
       */
    }
    return res.data;
  }
}

export const Notifications: NotificationModule = getModule(NotificationModule, store);
