
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ModelListSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import countries from '@/app/config/world.json';

@Component({
  components: {
    ModelListSelect,
  },
})
export default class Selector extends Vue {
  @Prop() context: Record<string, any>;
  get model() {
    return this.context.model;
  }
  countries = countries;
  disabledDates = { to: new Date(1930, 0, 5), from: new Date() };
}
