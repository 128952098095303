/* eslint-disable no-param-reassign */
import { createDecorator } from 'vue-class-component';
import * as localService from '@/services/app-storage';
import { errorNotify } from '@/shared/constants';
import RoleType from '../../../shared/constants/roles';
import { UserDto } from '../../../app/models/user/user.dto';
import { plainToClass } from 'class-transformer';

export interface TokenDto {
  expiresIn?: number;
  accessToken: string;
  refreshToken?: string;
}
export const TOKEN_STORAGE_KEY = 'auth_token';
export const USER_STORAGE_KEY = 'auth_user';
export const ROLE_STORAGE_KEY = 'auth_role';
export const NEW_STORAGE_KEY = 'is_new';

export const AUTH_EXPIRE_KEY = 'auth_exp';

export function getToken(): string | null {
  return localService.get(TOKEN_STORAGE_KEY) as string;
}

export function getUser(): UserDto {
  const storedUser = localService.get(USER_STORAGE_KEY) as UserDto;
  if (!storedUser) {
    return {} as UserDto;
  }
  return plainToClass(UserDto, storedUser);
}

export function getExpiry(): Date {
  const date: string = localService.get(AUTH_EXPIRE_KEY) as string;
  return new Date(date);
}

export function getRole(): RoleType | null {
  return localService.get(ROLE_STORAGE_KEY) as RoleType;
}

export function getIsNew(): boolean | null | undefined {
  return (localService.get(NEW_STORAGE_KEY) as boolean) || false;
}

export function setToken(data: string): Promise<void> {
  return localService.set(data, TOKEN_STORAGE_KEY);
}

export function setUser(data: UserDto): Promise<void> {
  return localService.set(data, USER_STORAGE_KEY);
}

export function setRole(data: RoleType): Promise<void> {
  return localService.set(data, ROLE_STORAGE_KEY);
}

export function setExpiry(data: Date): Promise<void> {
  return localService.set(data.toString(), AUTH_EXPIRE_KEY);
}

export function setIsNew(data: boolean): Promise<void> {
  return localService.set(data.toString(), NEW_STORAGE_KEY);
}

// decorators.js

// Declare Log decorator.
export const Loading = createDecorator((options, key) => {
  const text: any = {
    findGuest: 'Fetching your booking...',
    setGuest: 'Opening your pre check-in...',
    logout: 'See you next time...',
    upadteAccommocation: 'Updating...',
    /*     singUpGuest: 'agg',
     */ inviteGuest: 'Inviting guest...',
    /*     regiesterGuest: 'aww',
     */ archived: 'Fetching archived bookings...',
    complete: 'Creating accommodation...',
    submit: 'Saving...',
    updateAccommodation: 'Updating accommodation...',
    saveBooking: 'Creating booking...',
    updateGuest: 'Updating guest...',
    startCheckin: 'Starting the checkin...',
    findAllBookings: 'Finding all bookings...',
    search: 'Searching...',
    getPaymentData: 'Getting details...',
    upgradeCall: 'Upgrading...',
    deleteNotifications: 'Deleting notifications...',
    resendInvite: 'Resending invite...',
    saveMember: 'Adding member...',
    deleteMember: 'Deleting member',
    reload: 'Reloading...',
    submitForm: 'Submitting...',
    saveProfile: 'Updating profile...',
    findOneBooking: 'Fetching the booking...',
  };
  let words = 'Loading...';
  const test = Object.keys(text);
  test.forEach(function (k) {
    if (k == key) {
      words = text[key];
    }
  });
  // Keep the original method for later.
  if (options.methods) {
    const originalMethod = options.methods[key];
    let call = false;

    // Wrap the method with the logging logic.
    options.methods[key] = function wrapperMethod(...args) {
      const loading = this.$vs.loading({
        type: 'circles',
        color: 'primary',
        text: words,
      });
      call = true;
      // Invoke the original method.
      if (call) {
        call = false;
        return originalMethod
          .apply(this, args)
          .then(() => {
            loading.close();
            this.$emitter.emit('update');
            call = false;

            return true;
          })
          .catch((_err: any) => {
            loading.close();
            call = false;

            /*             this.$emitter.emit('error', err);
             */ return false;
          });
      }
      call = false;

      loading.close();
    };
  }
});

export function Loader(text: string) {
  return function (target: any, _key: string | symbol, descriptor: PropertyDescriptor) {
    const component = target;
    const original = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const loader = component.$vs.loading({
        target: document.getElementById('modal-loader'),
        type: 'circles',
        color: 'primary',
        text: text,
      });

      return original
        .apply(this, args)
        .then(() => {
          loader.close();
        })
        .catch(() => {
          loader.close();
        });
    };

    return descriptor;
  };
}
