/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
import userModule from '@/store/modules/user/user.module';
import * as msal from '@azure/msal-browser';
import { AccountInfo, EndSessionRequest, PopupRequest, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { isEmpty } from 'lodash';
import Vue from 'vue';
import b2cPolicies from '../../../app/config/msal/policy.config';
import { Auth } from '../../../store/modules/auth.module';
import { tenantModule } from '../../../store/modules/tenant/tenant.module';
import { buildAbilityFor } from '../../casl/ability';
import { DataObject, IAuth, iMSAL, Options, TokenRequest } from './types';

export class MSAL implements iMSAL {
  private msalLibrary: PublicClientApplication;

  private tokenExpirationTimers: { [key: string]: undefined | number } = {};

  public data: DataObject = {
    isAuthenticated: false,
    accessToken: '',
    idToken: '',
    user: { name: '', userName: '' },
    isNew: false,
    account: {
      homeAccountId: '',
      environment: '',
      tenantId: '',
      username: '',
      localAccountId: '',
      name: '',
      idTokenClaims: {},
    },
  };

  // Config object to be passed to Msal on creation.
  // For a full list of msal.js configuration parameters,
  // visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
  private auth: IAuth = {
    clientId: '',
    authority: '',
    redirectUri: '',
    knownAuthorities: [],
    requireAuthOnInitialize: false,
    onAuthentication: (_error, _response) => {},
    onToken: (_error, _response) => {},
    beforeSignOut: () => {},
  };

  private cache: msal.CacheOptions = {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  };

  // Add here scopes for id token to be used at MS Identity Platform endpoints.
  private loginRequest: PopupRequest = {
    scopes: ['openid', 'profile', 'User.Read'],
  };

  // Add here scopes for access token to be used at MS Graph API endpoints.
  private tokenRequest: SilentRequest = {
    scopes: ['https://checkinbuddySA.onmicrosoft.com/api/demo.write', 'https://checkinbuddySA.onmicrosoft.com/api/demo.read', 'openid'],
    account: this.data.account,
    forceRefresh: false,
  };

  private resetRequest = {
    scopes: ['User.Read'],
  };

  constructor(options: Options) {
    if (!options.auth.clientId) {
      throw new Error('auth.clientId is required');
    }
    this.auth = Object.assign(this.auth, options.auth);
    this.cache = Object.assign(this.cache, options.cache);
    this.loginRequest = Object.assign(this.loginRequest, options.loginRequest);
    this.tokenRequest = Object.assign(this.tokenRequest, options.tokenRequest);
    this.resetRequest = Object.assign(this.resetRequest, options.resetRequest);

    const config: msal.Configuration = {
      auth: this.auth,
      cache: this.cache,
    };
    this.msalLibrary = new msal.PublicClientApplication(config);

    if (this.auth.requireAuthOnInitialize) {
      this.signIn();
    }
  }

  private getAccount(): AccountInfo | undefined {
    // need to call getAccount here?
    const currentAccounts = this.msalLibrary.getAllAccounts();
    if (currentAccounts === null) {
      return undefined;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here

      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }

  async signIn(options?: any) {
    return this.msalLibrary
      .loginPopup({ ...this.loginRequest, ...options })
      .then(async (loginResponse: any) => {
        if (loginResponse !== null) {
          return this.handleResponse(loginResponse);
        } else {
          // need to call getAccount here?
          return this.getAccount();
        }
      })
      .catch(async (error: any) => {
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          try {
            return await this.msalLibrary.loginPopup(this.resetRequest);
          } catch (err) {
            return;
          }
        }
      });
  }

  async handleResponse(loginResponse: any) {
    if (loginResponse !== null) {
      this.data.user.userName = loginResponse.account.username;
      this.data.idToken = loginResponse.idToken;
      this.data.account = loginResponse.account;
      this.data.accessToken = loginResponse.accessToken;
      this.data.isNew = loginResponse.account.idTokenClaims.newUser ?? false;
      this.handleTokenResponse(null, loginResponse);

      if (loginResponse.state === 'register') {
        return Vue.prototype.$emitter.emit('registerEvent', loginResponse);
      } else if (loginResponse.state === 'guest') {
        return loginResponse;
      } else {
        return Vue.prototype.$emitter.emit('loginEvent', loginResponse);
      }
    } else {
      // need to call getAccount here?
      return this.getAccount();
    }
  }

  async memberTokenSignUp(token: string) {
    const request: msal.PopupRequest = {
      scopes: ['https://checkinbuddySA.onmicrosoft.com/api/demo.write', 'https://checkinbuddySA.onmicrosoft.com/api/demo.read', 'openid'],
      authority: b2cPolicies.authorities.redeemInvitation.authority,
      extraQueryParameters: { id_token_hint: token },
      state: 'member',
    };

    try {
      const loginResponse = await this.msalLibrary.loginPopup(request);
      await this.handleResponse(loginResponse);
    } catch (error: any) {
      throw new Error(error);
    }
  }
  async getInviteToken(tokenRequest: TokenRequest) {
    const request: msal.PopupRequest = {
      scopes: ['openid'],
      authority: b2cPolicies.authorities.signUpInvitation.authority,
      extraQueryParameters: {
        login_hint: tokenRequest.login_hint,
        accid: tokenRequest.accommodationId,
        atid: tokenRequest.key,
        usrr: tokenRequest.role,
      },
    };

    const response = await this.msalLibrary.loginPopup(request);

    return response.idToken;
  }

  signOut() {
    const logoutRequest: EndSessionRequest = {
      account: this.data.account,
    };
    this.msalLibrary.logoutPopup(logoutRequest);
    this.data.accessToken = '';
    this.data.idToken = '';
    this.data.user.userName = '';
  }

  async acquireToken(request = this.tokenRequest, retries = 0): Promise<any> {
    this.tokenRequest.account = this.getAccount();

    const claims = this.tokenRequest.account?.idTokenClaims as any;

    this.tokenRequest.authority = `https://checkinbuddySA.b2clogin.com/checkinbuddySA.onmicrosoft.com/${claims.acr}`;

    try {
      const response = await this.msalLibrary.acquireTokenSilent(request);

      if (!this.data.accessToken && !this.data.idToken) {
        this.data.user.userName = response?.account?.username as string;
        this.data.idToken = response.idToken;
        this.data.account = response.account as AccountInfo;
        this.data.accessToken = response.accessToken;
      }

      this.handleTokenResponse(null, response);

      return response;
    } catch (error: any) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return this.msalLibrary.acquireTokenPopup(request).catch((err: any) => {
          console.log(err);
        });
      }
      if (retries > 0) {
        return await new Promise((resolve) => {
          setTimeout(async () => {
            const res = await this.acquireToken(request, retries - 1);
            resolve(res);
          }, 5 * 1000);
        });
      }
      if (error.errorCode === 'silent_sso_error') {
        this.signIn();
      }
      return false;
    }
  }

  async isAuthenticated() {
    const [accounts] = this.msalLibrary.getAllAccounts();

    if (!accounts) {
      return false;
    }
    if (!this.data.accessToken) {
      await this.acquireToken();

      await Auth.login(this.data as any);

      if (!userModule.user) {
        throw new Error('No user');
      }
      userModule.setMember(userModule.user.member);

      const [member] = userModule.member;

      if (!isEmpty(member)) {
        tenantModule.setTenant(member.tenant);

        buildAbilityFor(member.role.ability as any);
        Vue.prototype.$ability.update(member.role.ability as any);
      }
    }

    return true;
  }

  async memberPasswordRedirect(): Promise<any> {
    try {
      return await this.msalLibrary.loginPopup(b2cPolicies.authorities.forgotPassword);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private handleTokenResponse(error: any, response: any) {
    if (error) {
      return;
    }
    if (this.data.accessToken !== response.accessToken) {
      this.setToken('accessToken', response.accessToken, response.expiresOn, response.scopes);
    }
    if (this.data.idToken !== response.idToken) {
      this.setToken('idToken', response.idToken.rawIdToken, new Date(response.idToken.expiration * 1000), this.tokenRequest);
    }
  }

  private setToken(tokenType: string, token: string, expiresOn: Date, scopes: SilentRequest) {
    const expirationOffset = 10000;
    const expiration = expiresOn.getTime() - new Date().getTime() - expirationOffset;
    /*     console.log(expiration);
     */ if (expiration >= 0) {
      this.data[tokenType] = token;
    }
    /*     console.log(this.tokenExpirationTimers);
     */ if (this.tokenExpirationTimers[tokenType]) clearTimeout(this.tokenExpirationTimers[tokenType]);
    this.tokenExpirationTimers[tokenType] = window.setTimeout(async () => {
      if (this.auth.autoRefreshToken) {
        await this.acquireToken(scopes, 3);
      } else {
        // @ts-ignore
        this.data[tokenType] = '';
      }
    }, expiration);
  }
}
