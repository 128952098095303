/* ============
 * Vue i18n
 * ============
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/app/locales/index.js';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  messages,
  silentFallbackWarn: true,
});

export default {
  i18n,
};
