import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { get } from '@/services/app-storage';
import themeConfig, { colors } from '@/app/config/themeConfig';
import RoleType from '../../shared/constants/roles';
import service from '../../services/app-http-client';

const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query: string) {
    return window.matchMedia(query).matches;
  };
  if ('ontouchstart' in window || window.TouchEvent) {
    return true;
  }
  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

@Module({ dynamic: true, store, name: 'app', namespaced: true })
export class AppModule extends VuexModule {
  public cookies: boolean = get('cookiesAccepted');

  public tourActive: boolean = get('tourActive');

  public questions: any = {};

  public accommodationType: any = {};

  public api = process.env.VUE_APP_API_URL;

  isTouchDevice = isTouchDevice();

  mainLayoutType = themeConfig.mainLayoutType || 'vertical';

  reduceButton = themeConfig.sidebarCollapsed;

  verticalNavMenuWidth = 'default';

  verticalNavMenuItemsMin = false;

  scrollY = 0;

  theme = get('theme') || 'light';

  darkMode = false;

  themePrimaryColor = colors.primary;

  loader = document.getElementById('loading-bg');

  notification = {};

  windowWidth = 0;

  isLoading = true;

  roleState = RoleType.Guest;

  get cookiesAccepted(): boolean {
    return this.cookies;
  }

  scrollbarTag(state: { isTouchDevice: any }) {
    return state.isTouchDevice ? 'div' : 'VuePerfectScrollbar';
  }

  @Mutation
  NOTIFY(payload: any): void {
    this.notification = payload;
  }

  @Mutation
  TOGGLE_REDUCE_BUTTON(val: any): void {
    this.reduceButton = val;
  }

  @Mutation
  UPDATE_MAIN_LAYOUT_TYPE(val: any): void {
    this.mainLayoutType = val;
  }

  @Mutation
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(val: any): void {
    this.verticalNavMenuItemsMin = val;
  }

  @Mutation
  UPDATE_VERTICAL_NAV_MENU_WIDTH(width: any): void {
    this.verticalNavMenuWidth = width;
  }

  @Mutation
  UPDATE_PRIMARY_COLOR(val: any): void {
    this.themePrimaryColor = val;
  }

  @Mutation
  UPDATE_THEME(val: any): void {
    this.theme = val;
  }

  @Mutation
  UPDATE_WINDOW_WIDTH(width: any): void {
    this.windowWidth = width;
  }

  @Mutation
  UPDATE_WINDOW_SCROLL_Y(val: any): void {
    this.scrollY = val;
  }

  @Action
  updateWindowWidth(payload: any): void {
    this.UPDATE_WINDOW_WIDTH(payload);
  }

  @MutationAction({ mutate: ['tourActive'] })
  async updateTourActive(tourActive: boolean) {
    localStorage.setItem('tourActive', JSON.stringify(tourActive));
    return {
      tourActive,
    };
  }

  @MutationAction({ mutate: ['questions', 'accommodationType'] })
  async findQuestions() {
    const { data } = await service.get('admin/appData');
    return {
      questions: data.questions,
      accommodationType: data.accommodationType,
    };
  }

  @MutationAction({ mutate: ['darkMode'] })
  async updateTheme(mode: boolean) {
    localStorage.setItem('mode', JSON.stringify(mode));
    return {
      darkMode: mode,
    };
  }
}

export const appModule: AppModule = getModule(AppModule);
