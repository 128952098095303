
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Counter extends Vue {
  @Prop() context: Record<string, any>;

  count = 0;

  get counter() {
    return this.count;
  }

  set counter(val: number) {
    // if (this.context.name === 'numberOfGuests' && this.context.model === 1) {
    //   return;
    // }
    this.count = val;
    this.context.model = val;
  }

  mounted() {
    this.count = this.context.model ?? 0;
  }

  addMinor() {
    this.count++;
    this.context.model = this.count;
  }

  removeMinor() {
    if (this.context.name === 'numberOfGuests' && this.context.model === 1) {
      return;
    }
    if (this.context.model > 0) {
      this.count--;
      this.context.model = this.count;
    }
  }
}
