import { IMemberDto } from '@/app/models';
import { getUser, setUser } from '@/modules/auth/store/auth.service';
import service from '@/services/app-http-client';
import store from '@/store';
import Vue from 'vue';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { UserDto } from '../../../app/models/user/user.dto';

export interface UserState {
  user?: Partial<UserDto> | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  notify?: {};
}
@Module({
  dynamic: true,
  store,
  name: 'user',
  namespaced: true,
  // preserveState: localStorage.getItem('vuex') !== null
})
class Auth extends VuexModule implements UserState {
  public user = getUser();

  public azureResponse = {};

  public signedInAs = '';

  public member: IMemberDto[] = [];

  public channel = {};

  get role() {
    return this.user?.role ?? [];
  }

  get organisation() {
    return this.user?.tenant;
  }

  @Mutation
  private SET_USER(user: any) {
    this.user = { ...this.user, ...user };
  }

  @Action
  public AUTH_LOGOUT() {
    this.SET_USER({} as UserDto);
    localStorage.clear();
    // this.SET_ROLE(RoleType.Public);
  }

  @Mutation
  public setMember(payload: IMemberDto[]) {
    this.member = payload;
  }

  @MutationAction({ mutate: ['user', 'member'] })
  async getUser() {
    const { data: user } = await service.get<UserDto>('auth/me');

    return {
      user,
      member: user.member,
    };
  }

  // @Action
  // public async signIn(authResponse: msal.AuthenticationResult): Promise<any> {
  //   this.SET_AZURE_RES(authResponse);
  //   const response = await service.get('auth', {
  //     headers: {
  //       Authorization: `Bearer ${authResponse.idToken}`,
  //     },
  //     params: {
  //       userRole: loginRequest.state,
  //     },
  //   });
  //   if (response.status === 401) {
  //     throw new Error('Incorrect user details');
  //   }
  //   const user = response.data;

  //   if (!isEmpty(user.member)) {
  //     if (user.member) {
  //       this.context.commit('tenant/setTenant', user.member[0].tenant, { root: true });
  //     }

  //     if (user.keys) {
  //       this.context.dispatch('tenant/setTenantId', user.keys, { root: true });
  //     }
  //   }
  //   this.SET_USER(user);

  //   setUser(user);
  //   localStorage.setItem('isUser', 'true');

  //   return user.member[0];
  // }

  @MutationAction({ mutate: ['user'] })
  async registerGuestUser(payload: any) {
    const res = await service.post<UserDto>(`auth/register/guest/${payload.token}`, payload.data).catch((error) => {
      throw new Error(error.response);
    });
    const user = res.data;
    return { user };
  }

  @Action
  async logoutUser() {
    try {
      const { data } = await service.post('auth/logout');

      if (!data) {
        throw new Error('Logout failed');
      }
      await Vue.prototype.$msal.signOut();
      //   return auth.getTokenRedirect();
    } catch (error: any) {
      throw new Error(error);
    }
  }

  @Action
  public setUser(user: any) {
    this.SET_USER(user);
  }

  @Action
  public async updateGuestUser() {
    if (!this.user) {
      throw new Error('No user to update');
    }

    const { data } = await service.patch<UserDto>(`user/update/guest`, this.user);
    this.SET_USER(data);
    setUser(data as any);
    return data;
  }
}

const userModule: Auth = getModule(Auth, store);

export { userModule as default };
