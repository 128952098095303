import router from '@/router';
import emitter from './emitter';

emitter.on('guestAuthenticated', (idTokenClaim: any) => {
  if (idTokenClaim) {
    router.push('/guest/dashboard');
  }
});

emitter.on('memberAuthenticated', (idTokenClaim: any) => {
  if (!idTokenClaim.newUser) {
    router.push('/organisation/dashboard');
  } else {
    router.push('/register');
  }
});

emitter.on('registerAuthenticated', (idTokenClaim: any) => {
  if (idTokenClaim) {
    router.push('/register');
  }
});
