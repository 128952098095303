<script>
import { appModule } from '@/store/modules/app.module';

export default {
  name: 'NavVTour',
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: '<span style="font-weight:700">Welcome!</span>',
          },
          content:
            'Please view our video tutorials located in the help center. This will ensure you get the most value out of Check in Buddy.',
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="2"]',
          /*           header: {
            title: '<span style="font-weight:700">Welcome to Check-in Buddy!</span>',
          }, */
          content: 'Through this dashboard, you can manage your upcoming bookings and access all important guest information.',
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="3"]',
          header: {
            title: 'This is your home <span style="font-weight:700">Dashboard.</span>',
          },
          content: 'Here you can view analytics about your bookings and see notifications.',
          params: {
            placement: 'bottom-start',
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="4"]',
          header: {
            title: 'This is your <span style="font-weight:700">Bookings tab. </span>',
          },
          content: 'Here you can create your upcoming bookings, view the check-in status of your guests and access guest information.',
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="5"]',
          header: {
            title: 'This is your <span style="font-weight:700">Accommodations tab.</span>',
          },
          content: 'Here you can create your accommodations and view the QR code for each accommodation.',
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="6"]',
          header: {
            title: 'This is your <span style="font-weight:700">Account tab</span>',
          },
          content: 'Here you can view your profile and billing information, as well as add new team members to your account.',
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="7"]',
          header: {
            title: 'Here you can see your <span style="font-weight:700">Account Credits.</span>',
          },
          content:
            'Each credit will allow you to invite an adult to your booking. You can purchase a credit subscription by clicking here.',
          params: {
            placement: 'bottom-end',
            enableScrolling: false,
          },
        },
      ],
      myCallbacks: {
        onPreviousStep: this.prevStepCallback,
        onNextStep: this.nextStepCallback,
        onSkip: this.onSkip,
        onFinish: this.onFinish,
      },
    };
  },
  mounted() {
    const NavVTourSee = JSON.parse(window.localStorage.getItem('NavVTourSee'));
    if (NavVTourSee == null || NavVTourSee) {
      setTimeout(() => {
        try {
          this.$tours.NavVTour.start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    prevStepCallback(currentStep) {
      if (currentStep === 2) {
        /*         console.log(currentStep);
         */
      }
    },
    nextStepCallback(currentStep) {
      if (currentStep === 1) {
        /*         console.log(currentStep);
         */
      }
    },
    onSkip(currentStep) {
      appModule.updateTourActive(false);
      window.localStorage.setItem('NavVTourSee', false);
      window.localStorage.setItem('BookingVTourSee', false);
      window.localStorage.setItem('AccommodationVTourSee', false);
    },
    onFinish() {
      // appModule.updateTourActive(false);
      window.localStorage.setItem('NavVTourSee', false);
    },
  },
};
</script>
<template>
  <v-tour name="NavVTour" :steps="steps" :options="{ highlight: false }" :callbacks="myCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header {
  background: #ea5455 !important;
}
.v-step__arrow {
  border-color: #ea5455 !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
</style>
