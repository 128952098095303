
import { Component, Prop, Vue } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';
import { guestModule } from '../../../store/modules/guest.module';

@Component({
  components: {
    Datepicker,
  },
})
export default class DobPicker extends Vue {
  @Prop() context: Record<string, any>;

  emitSelected(val: any) {
    const dobValidation = document.querySelector('.validation') as any;
    dobValidation.innerHTML = '';
    guestModule.setDobValid(val);
  }

  get model() {
    return this.context.model;
  }
  disabledDates = { to: new Date(1930, 0, 5), from: new Date() };
}
