/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations as TracingIntegrations } from '@sentry/tracing'; // Must import second
import smartlookClient from 'smartlook-client';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const version = require('../../../package.json').version;

if (!(window as any).__PRERENDER_INJECTED) {
  Sentry.init({
    Vue,
    tracingOptions: {
      trackComponents: true,
    },
    dsn: process.env.VUE_APP_INTEGRATIONS_LOGGING_SENTRY,
    release: 'cib-client@' + version,
    environment: process.env.NODE_ENV,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', 'cib-api-as.azurewebsites.net', 'cib-server-staging-as.azurewebsites.net'],
      }),
    ],
    logErrors: process.env.NODE_ENV !== 'production',
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.3,
  });

  if (process.env.VUE_APP_MODE === 'production') {
    smartlookClient.init(process.env.VUE_APP_INTEGRATIONS_SMARTLOOK);
  }
}
