







































export default {
  name: 'VxBreadcrumb',
};
