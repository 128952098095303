
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { parsePhoneNumber, parseIncompletePhoneNumber, formatIncompletePhoneNumber, ParseError } from 'libphonenumber-js/max';
import { guestModule } from '../../../store/modules/guest.module';

@Component
export default class CountryCode extends Vue {
  @Prop() context: Record<string, any>;
  @Prop() disable: boolean;

  country = 'ZA';
  errorMessage: null | string = null;
  phoneNumber = '';
  phoneNumberDetails = {} as any;
  phoneNumberFormatted = '';
  get model() {
    return this.context.model;
  }

  @Watch(`context.model`)
  handleAuth(ctx: any) {
    if (ctx.number) {
      this.phoneNumber = ctx.number;
      this.country = ctx.code;
      this.phoneNumberFormatted = ctx.nationalNumber;
    }
    this.parsePhoneNumber();
  }

  created() {
    if (this.context.model === '') {
      this.context.model = {};
    }

    if (this.context.model.number) {
      this.phoneNumber = this.context.model.number;
      this.country = this.context.model.code;
      this.phoneNumberFormatted = this.context.model.nationalNumber;
      this.parsePhoneNumber();
    }
  }

  get phoneNumberInput() {
    if (this.phoneNumber) {
      // return this.phoneNumberFormatted;
      return this.phoneNumber;
    }
    return '';
  }

  onSelect({ iso2 }: Record<string, string>) {
    this.country = iso2;
    this.parsePhoneNumber();
  }

  onInput(event: any) {
    /*     console.log(event);
     */ if (!event) {
      return;
    }

    let value = event.target.value;
    if (!this.country) {
      if (value.length && value[0] !== '!') {
        value = '+' + value;
      }
    }
    const number = parseIncompletePhoneNumber(value);

    // Workaround for the `(xxx)` backspace issue
    this.phoneNumber = formatIncompletePhoneNumber(number);
    // number === this.phoneNumber && formatIncompletePhoneNumber(number, this.country as any).indexOf(value) === 0
    //   ? number.slice(0, -1)
    //   : number;

    this.parsePhoneNumber();
  }

  parsePhoneNumber() {
    try {
      if (!this.phoneNumber) {
        this.context.model.number = null;
        this.context.model.code = this.country;
        this.context.model.numberFormatted = null;
        this.context.model.nationalNumber = null;
        return;
      }

      const code = this.country as any;
      const phoneNumber = parsePhoneNumber(this.phoneNumber, code);
      const arr = phoneNumber.formatInternational().split(' ');
      arr.shift();
      this.phoneNumber = arr.join(' ');

      this.phoneNumberDetails = {
        country: phoneNumber.country,
        countryCallingCode: phoneNumber.countryCallingCode,
        number: phoneNumber.number,
        numberFormatted: phoneNumber.formatInternational(),
        nationalNumber: phoneNumber.nationalNumber,
        nationalNumberFormatted: this.phoneNumberFormatted,
        uri: phoneNumber.getURI(),
        possible: phoneNumber.isPossible(),
        valid: phoneNumber.isValid(),
        type: phoneNumber.getType(),
      };
      if (phoneNumber.country) {
        this.country = phoneNumber.country;
        // this.$emit('country', this.country);
      }
      const validityMessage = this.phoneNumberDetails.valid ? '' : 'Invalid phone number';
      this.setValidityErrorMessage(validityMessage);

      if (this.phoneNumberDetails.valid || this.phoneNumberDetails.valid === '') {
        // this.context.model[0] = {
        // this.context.model.number = phoneNumber.number;
        // this.context.model.code = this.country;
        // this.context.model.numberFormatted = phoneNumber.formatInternational();
        // this.context.model.nationalNumber = phoneNumber.nationalNumber;

        this.context.model = {
          number: phoneNumber.number,
          code: this.country,
          numberFormatted: phoneNumber.formatInternational(),
          nationalNumber: phoneNumber.nationalNumber,
        };
        // };
      }
      // this.$emit('value', this.phoneNumberDetails);

      return this.phoneNumberDetails;
    } catch (error: any) {
      const message = error instanceof ParseError ? this.errorMessage : error;
      this.setValidityErrorMessage(message);
    }
  }

  setValidityErrorMessage(message: string | null) {
    this.errorMessage = message;
  }

  get num() {
    return this.phoneNumber;
  }
}
