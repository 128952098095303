import BaseEntity from '@/app/entities/entity';
import { IProfileDto } from '../../models/user/user.dto';
import UserDto from '../user/user.dto';
import { IBookingDto, IMinorDto } from './IBookingDto';
import Indemnity from './Indemnity.dto';
import MinorDto from './minor.dto';

export default class Guest extends BaseEntity<string> {
  url: string;
  firstName?: string;
  lastName?: string;
  address: IGuestAddress = {
    street: '',
    addressLineOne: '',
    addressLineTwo: '',
    code: '',
    city: '',
    country: '',
  };
  sdf?: any;
  token?: string;
  mobileNumber?: string;
  email?: string;
  roomNumber?: any;
  additionalNotes?: any;
  dietaryRequirements?: any;
  allergies?: any;
  medicalConditions?: any;
  specialRequirements?: any;
  vaccinated?: any;
  vaccineUpToDate?: any;
  guestArrival?: any;
  luggageHandling?: any;
  isPrimary?: boolean;
  invited?: boolean;
  userId?: string;
  guestProfileId?: any;
  bookingId?: string;
  status?: string;
  minor!: MinorDto[];
  minorCount?: number;
  healthCheck?: any;
  phone?: any;
  signature?: any;
  healthSignature?: any;
  user?: UserDto;
  profile: IProfileDto;
  booking?: IBookingDto;
  indemnity: Indemnity[];
  guestAnswers: GuestAnswers[] = [];
  resident: any;
  identification: any;
  travelIdDocument: any;
  nationality: any;
  passportId: any;
  dateOfBirth: any;
  guestAdditionalInformation: any;
}

export interface IGuestAddress {
  id?: number;
  street: string;
  addressLineOne: string;
  addressLineTwo: string;
  code: string;
  city: string;
  country: string;
}

export interface GuestAnswers {
  answer: { value: any };
  healthQuestionsId: number;
}
