var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"fixed z-10 inset-0 overflow-y-auto"},[_c('div',{staticClass:"flex items-end justify-center pt-4 px-4 pb-20 text-center"},[_c('transition',{attrs:{"enter-active-class":"ease-out duration-300","enter-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"ease-in duration-200","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"fixed inset-0 transition-opacity",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"absolute inset-0 bg-gray-700 dark:bg-white opacity-25"})])]),_c('span',{staticClass:"sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('transition',{attrs:{"enter-active-class":"ease-out duration-300","enter-class":"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95","enter-to-class":"opacity-100 translate-y-0 sm:scale-100","leave-active-class":"ease-in duration-200","leave-class":"opacity-100 translate-y-0 sm:scale-100","leave-to-class":"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"\n          m-auto\n          align-bottom\n          modal\n          bg-white\n          dark:bg-gray-700\n          rounded-lg\n          text-left\n          overflow-hidden\n          shadow-xl\n          transform\n          transition-all\n          modalMax\n        ",class:{
          'lg:w-8/12': _vm.lgScreen,
          'lg:w-10/12': _vm.pricingScreen,
          'w-5/12': _vm.qrScreen,
          'w-2/3': _vm.receiptScreen,
          'w-6/12': _vm.teamsScreen,
          'md:max-w-full': _vm.littleScreen,
        },attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[_c('div',{staticClass:"close"},[_c('font-awesome-icon',{class:{
              'text-black': _vm.closeBlack,
              'text-white': _vm.closeWhite,
              'text-red-700': _vm.closeRed,
            },attrs:{"icon":"times","size":"lg"},on:{"click":function($event){return _vm.closeDialog()}}})],1),_vm._t("header"),_vm._t("default"),_vm._t("footer")],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }