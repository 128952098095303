/* eslint-disable no-inner-declarations */
/* eslint-disable no-param-reassign */
export * from './casl';
export * from './event-bus';
export * from './font-awesome';
export * from './intergrations';
export * from './msal';
export * from './sweet-alert';
export * from './toastification';
export * from './vuex-persistance';
export * from './websockets';
import ClickOutside from 'vue-click-outside';
import VueSocketIo from '@/plugins/websockets';

import Vue from 'vue';
import dayjs from 'dayjs';
import VueCountryCode from 'vue-country-code';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import VueClipboard from 'vue-clipboard2';
VueSocketIo.init(Vue);

Vue.use(VueClipboard);
Vue.directive('ClickOutside', ClickOutside);
Vue.use(VueCountryCode);
Vue.directive('expand', {
  inserted(el, binding) {
    if (binding.value !== null) {
      function calcHeight() {
        const currentState = el.getAttribute('aria-expanded');

        el.classList.add('u-no-transition');
        el.removeAttribute('aria-expanded');
        el.style.height = null || '';
        el.style.height = `${el.clientHeight}px`;
        el.setAttribute('aria-expanded', currentState as string);

        setTimeout(function () {
          el.classList.remove('u-no-transition');
        });
      }

      el.classList.add('expand');
      el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
      calcHeight();
      window.addEventListener('resize', calcHeight);
    }
  },
  update(el, binding) {
    if (el.style.height && binding.value !== null) {
      el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
    }
  },
});

Vue.filter('formatDate', function (value: any) {
  if (value) {
    return dayjs(dayjs(value), 'MM-DD-YYYY');
  }
  return undefined;
});

dayjs.extend(customParseFormat);
require('vue-tour/dist/vue-tour.css');
