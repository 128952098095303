import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import service from '@/services/app-http-client';
import store from '@/store';
import { IBookingDto, IGuestDto } from '@/app/entities';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import Guest from '../../app/entities/tenant/guest.dto';
import MinorDto from '../../app/entities/tenant/minor.dto';

type BookingHash = { [key: string]: IBookingDto };
@Module({ dynamic: true, store, name: 'guest', namespaced: true })
export class GuestModule extends VuexModule {
  guest: Guest = {} as Guest;

  guests: Guest[] = [] as Guest[];

  booking: IBookingDto = {} as IBookingDto;

  bookings: BookingHash = {};

  organisationId = '';

  isCheckIn = false;

  editingGuestFlag = false;

  personalFormValid = false;

  arrivalFormValid = false;

  medicalFormValid = false;

  extraInfoFormValid = false;

  dobValid = false;

  phoneValid = false;

  @MutationAction({ mutate: ['isCheckIn'] })
  public async setIsCheckIn(token: boolean) {
    return {
      isCheckIn: token,
    };
  }
  @MutationAction({ mutate: ['guest'] })
  public async setGuest(guest: Guest) {
    return {
      guest: guest,
    };
  }

  @MutationAction({ mutate: ['editingGuestFlag'] })
  public async setEditingFlag(flag: boolean) {
    return {
      editingGuestFlag: flag,
    };
  }

  @MutationAction({ mutate: ['guest', 'guests', 'booking'] })
  async fetchGuest(payload: { token: string }): Promise<{
    guest: Guest;
    guests: Guest[];
    booking: IBookingDto;
  }> {
    const res = await service.get(`guest/find/${payload.token}`, {
      headers: {
        'x-organisation-id': `${tenantModule.apiKey}`,
        Authorization: `Bearer ${payload.token}`,
      },
    });
    if (!res.data) {
      throw new Error('No data');
    }
    const booking: IBookingDto = res.data;

    // if (!booking || !booking.guest) {
    //   throw new Error('Booking not found');
    // }

    const response = {
      guest: booking.guest.find((val) => val.token === payload.token) as Guest,
      guests: booking.guest as Guest[],
      booking,
    };
    return response;
  }

  @Mutation
  addBooking(booking: IBookingDto): void {
    this.bookings[booking.id] = booking;
  }

  @MutationAction
  async setCurrentBooking(payload: any): Promise<{
    booking: any;
  }> {
    return { booking: payload };
  }

  // Check-in form validation

  @MutationAction({ mutate: ['personalFormValid'] })
  async setPersonalFormIsValid(isPersonalValid: boolean) {
    return { personalFormValid: isPersonalValid };
  }

  @MutationAction({ mutate: ['arrivalFormValid'] })
  async setArrivalFormIsValid(isArrivalValid: boolean) {
    return { arrivalFormValid: isArrivalValid };
  }

  @MutationAction({ mutate: ['medicalFormValid'] })
  async setMedicalFormIsValid(isMedicalValid: boolean) {
    return { medicalFormValid: isMedicalValid };
  }

  @MutationAction({ mutate: ['extraInfoFormValid'] })
  async setExtraInfoFormIsValid(isExtraInfoValid: boolean) {
    return { extraInfoFormValid: isExtraInfoValid };
  }

  /*****************/

  @MutationAction({ mutate: ['phoneValid'] })
  async setPhoneValid(isValid: boolean) {
    return { phoneValid: isValid };
  }

  @MutationAction({ mutate: ['dobValid'] })
  async setDobValid(isValid: boolean) {
    return { dobValid: isValid };
  }

  @MutationAction({ mutate: ['bookings'] })
  async findAllBookings(params?: any) {
    const res = await service.get(`booking/${tenantModule.tenant.id}`, { params });
    const bookings: BookingHash = {};
    res.data.forEach((booking: any) => {
      bookings[booking.id] = booking;
    });
    return { bookings };
  }

  @Mutation
  setOrganisationId(id: string): void {
    this.organisationId = id;
  }

  @Action
  updateOrganisationId(id: string): void {
    if (id) {
      this.setOrganisationId(id);
    }
  }

  @Action
  async startCheckIn(obj: { guestInfo: Guest }): Promise<any> {
    const res = await service.post(`guest/update/${obj.guestInfo.id}`, obj);
    return res.data;
  }

  @Action
  async uploadImage(_baseImg: any): Promise<any> {
    const res = await service.post(`${process.env.VUE_APP_API_URL}/v1/api/guest/upload`, {
      signature: _baseImg.signature,
      id: _baseImg.id,
    });
    return res.data;
  }

  @Action
  async updateGuest(obj: Partial<{ guestInfo: IGuestDto }>): Promise<any> {
    // eslint-disable-next-line no-param-reassign
    if (!obj.guestInfo) {
      return ':)';
    }
    const res = await service.put(`guest/update/${obj.guestInfo.id}?org=1`, obj);
    return res.data;
  }

  @Action
  async updateMinor(minorInfo: MinorDto): Promise<any> {
    // eslint-disable-next-line no-param-reassign
    if (!minorInfo) {
      return ':(';
    }
    const res = await service.put(`guest/updateMinor/${minorInfo.id}?org=1`, minorInfo);
    return res.data;
  }

  @Action
  async updateGuestBooking(obj: any): Promise<any> {
    // eslint-disable-next-line no-param-reassign
    const res = await service.put(`booking/${obj.id}/guest/${obj.payload.id}`, obj.payload);
    return res.data;
  }

  @Action
  async createBooking(obj: Partial<IBookingDto>): Promise<IBookingDto> {
    const res = await service.post<IBookingDto>('booking', obj);
    if (res.data) {
      this.addBooking(res.data);
    }
    return res.data;
  }

  @Action
  async deleteGuest(obj: Partial<any>): Promise<any> {
    const res = await service.delete(`guest/single/${obj.id}/${obj.bookingId}`);
    return res.data;
  }
  //test
  @Action
  async deleteBooking(obj: Partial<IBookingDto>): Promise<IBookingDto> {
    const res = await service.delete<IBookingDto>(`booking/${obj.id}`);

    return res.data;
  }

  @Action
  async createGuest(obj: { guests: Partial<Guest | Guest[]>; booking: IBookingDto }): Promise<Guest> {
    const res = await service.post<Guest>('guest', {
      guests: obj.guests,
      booking: obj.booking,
    });
    return res.data;
  }

  @MutationAction({ mutate: ['bookings'] })
  async findGuestBooking(bookingId: string) {
    const { data } = await service.get<IBookingDto>(`booking/guest/${bookingId}`);
    const bookings = store.state.guest.bookings;
    bookings[data.id] = data;
    return {
      bookings,
    };
  }
}

export const guestModule: GuestModule = getModule(GuestModule, store);
