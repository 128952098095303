import { Ability } from '@casl/ability';
import RawRule from './rule.interface';

export const initialAbility = [
  {
    action: 'read',
    subject: 'tenant',
  },
];
const userData = JSON.parse(localStorage.getItem('auth_user') as string);
const existingAbility = userData ? userData.abilities : null;

export type AppAbility = Ability<[any, any]>;
export const AppAbility = new Ability(existingAbility || initialAbility);

export function buildAbilityFor(rule: RawRule[]): AppAbility {
  return new Ability(rule);
}
