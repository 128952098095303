
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SvgIcon extends Vue {
  @Prop() size: number;

  @Prop() path: string;

  @Prop() polyline: string;
}
