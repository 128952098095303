export const apiConfig = {
  b2cScopes: ['https://checkinbuddySA.onmicrosoft.com/api/demo.write', 'https://checkinbuddySA.onmicrosoft.com/api/demo.read'],
  webApi: `${process.env.VUE_APP_API_URL}/v1/api/auth/callback`,
};
export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};
export const loginRequest = {
  scopes: [
    'https://checkinbuddySA.onmicrosoft.com/api/demo.write',
    'https://checkinbuddySA.onmicrosoft.com/api/demo.read',
    // 'a7155f6d-c0a5-4d0d-aa29-e7900033c1d5',
    'openid',
  ],
  state: 'owner',
};
