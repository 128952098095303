// import { AxiosInstance } from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import { config } from 'vuex-module-decorators';
import Component from 'vue-class-component';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);
config.rawError = true;
Vue.use(Vuex);

const store = new Vuex.Store<any>({
  strict: false,
});

export default store;
