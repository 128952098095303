/* eslint-disable no-param-reassign */
/* eslint-disable no-inner-declarations */
import Counter from '@/app/components/counter/Counter.vue';
import CountryCode from '@/app/components/country-code/CountryCode.vue';
import Dialog from '@/app/components/dialog/Dialog.vue';
import VueGeolocation from '@/app/utils/geolocation';
import VxBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import CibButton from '@/components/buttons/CibButton.vue';
import CbIcon from '@/components/cb-icon/cbIcon.vue';
import Progress from '@/components/common/progress-bar/Progress.vue';
import Datepicker from '@/components/datepicker/Datepicker.vue';
import FeatherIcon from '@/components/FeatherIcon.vue';
import GoogleAutocomplete from '@/components/google-autocomplete/GoogleAutocomplete.vue';
import ModalComponent from '@/components/modals/ErrorModal.vue';
import HcSelect from '@/components/select/HcSelect.vue';
import SvgIcon from '@/components/svg/Svg-icon.vue';
import VxCard from '@/components/vx-card/VxCard.vue';
import VxDivider from '@/components/vx-divider/vsDivider.vue';
import VxInputGroup from '@/components/vx-input-group/VxInputGroup.vue';
import VxList from '@/components/vx-list/VxList.vue';
import VxTab from '@/components/vx-tabs/vsTab.vue';
import msal from '@/plugins/msal/msal';
import VuePusher from '@/plugins/websockets';
import '@/shared/directives/Expand';
import VueFormulate from '@braid/vue-formulate';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';
import VueApexCharts from 'vue-apexcharts';
import ClickOutside from 'vue-click-outside';
import VueCountryCode from 'vue-country-code';
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import 'vue2-timepicker/dist/VueTimepicker.css';
import VueSignaturePad from 'vue-signature-pad';
import VueTour from 'vue-tour';
import * as VueGoogleMaps from 'vue2-google-maps';
import msalConfig from '../app/config/msal/auth.config';
import service from '../services/app-http-client';
import dobPicker from '@/app/components/dob-picker/DobPicker.vue';
import Timepicker from '@/app/components/forms/timepicker/Timepicker.vue';
import VueTimepicker from '@/app/components/forms/timepicker/VueTimepickerPlus.vue';
import FormulateObjectPersonal from '@/app/components/forms/object/FormulateObjectPersonal.vue';
import FormulateObjectArrival from '@/app/components/forms/object/FormulateObjectArrival.vue';
import FormulateObjectMedical from '@/app/components/forms/object/FormulateObjectMedical.vue';
import FormulateObjectExtraInfo from '@/app/components/forms/object/FormulateObjectExtraInfo.vue';
import EasySlider from 'vue-easy-slider';
import Selector from '@/components/selector/selector.vue';
import S3UploaderPlugin from '../services/file';
import { isEmpty } from 'lodash';

Vue.use(VueCountryCode);
Vue.use(EasySlider);

dayjs.extend(customParseFormat);
require('vue-tour/dist/vue-tour.css');

Vue.use(msal, msalConfig);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAObJm51DoP3eVWOpU1UxEkiccsQ7ds7ew',
    libraries: 'places',
    installComponents: true,
  },
});
Vue.directive('ClickOutside', ClickOutside);

const datepickerOptions = {
  colors: {
    selected: '#f05252',
    inRange: '#f05252',
  },
};

Vue.use(VueTour);
Vue.use(PortalVue);
Vue.use(VueFormWizard);
Vue.use(VueSignaturePad);
Vue.use(VueGeolocation);
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
// Vue.use(FileService);
Vue.use(VueFormulate, {
  classes: {
    input: 'ring-red-500',
  },
  rules: {
    invite: ({ getFormValues }: { getFormValues: any }) => {
      if (getFormValues().email || getFormValues().phone?.number) {
        return true;
      }
    },
    phone: ({ value }: any) => !isEmpty(value.number),
  },
  library: {
    datepicker: {
      classification: 'text',
      component: 'Datepicker',
    },
    googleAuto: {
      classification: 'text',
      component: 'GoogleAutocomplete',
    },
    countryCode: {
      classification: 'text',
      component: 'CountryCode',
      slotProps: { component: ['disable'], help: ['required'] },
    },
    counter: {
      classification: 'text',
      component: 'Counter',
    },
    dobPicker: {
      classification: 'text',
      component: 'DobPicker',
    },
    timepicker: {
      classification: 'text',
      component: 'Timepicker',
    },
    objectPersonal: {
      classification: 'objectPersonal',
      type: 'group',
      component: 'FormulateObjectPersonal',
      repeatable: false,
    },
    objectArrival: {
      classification: 'objectArrival',
      type: 'group',
      component: 'FormulateObjectArrival',
      repeatable: false,
    },
    objectMedical: {
      classification: 'objectMedical',
      type: 'group',
      component: 'FormulateObjectMedical',
      repeatable: false,
    },
    objectExtraInfo: {
      classification: 'objectExtraInfo',
      type: 'group',
      component: 'FormulateObjectExtraInfo',
      repeatable: false,
    },
    selector: {
      classification: 'select',
      component: 'Selector',
    },
  },
  uploadUrl: 'https://cq2cm6d0h6.execute-api.us-east-1.amazonaws.com/signature',
  plugins: [S3UploaderPlugin],
});
Vue.component('Selector', Selector);

Vue.component('Timepicker', Timepicker);
Vue.component('VueTimepicker', VueTimepicker);
Vue.component('Datepicker', Datepicker);
Vue.component('FormulateObjectPersonal', FormulateObjectPersonal);
Vue.component('FormulateObjectArrival', FormulateObjectArrival);
Vue.component('FormulateObjectMedical', FormulateObjectMedical);
Vue.component('FormulateObjectExtraInfo', FormulateObjectExtraInfo);
Vue.component('CountryCode', CountryCode);
Vue.component('Counter', Counter);
Vue.component('GoogleAutocomplete', GoogleAutocomplete);
Vue.component('DobPicker', dobPicker);
Vue.component('Apexchart', VueApexCharts);
Vue.component('CbIcon', CbIcon);
Vue.component(VxDivider.name, VxDivider);
Vue.component(VxCard.name, VxCard);
Vue.component(VxList.name, VxList);
Vue.component(VxBreadcrumb.name, VxBreadcrumb);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(VxInputGroup.name, VxInputGroup);
Vue.component('CibButton', CibButton);
Vue.component('HcSelect', HcSelect);
Vue.component('SvgIcon', SvgIcon);
Vue.component('CibModal', ModalComponent);
Vue.component('ProgressBar', Progress);
Vue.component('VsDialog', Dialog);

Vue.directive('expand', {
  inserted(el, binding) {
    if (binding.value !== null) {
      function calcHeight() {
        const currentState = el.getAttribute('aria-expanded');

        el.classList.add('u-no-transition');
        el.removeAttribute('aria-expanded');
        el.style.height = null || '';
        el.style.height = `${el.clientHeight}px`;
        el.setAttribute('aria-expanded', currentState as string);

        setTimeout(function () {
          el.classList.remove('u-no-transition');
        });
      }

      el.classList.add('expand');
      el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
      calcHeight();
      window.addEventListener('resize', calcHeight);
    }
  },
  update(el, binding) {
    if (el.style.height && binding.value !== null) {
      el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
    }
  },
});

Vue.filter('formatDate', function (value: any) {
  if (value) {
    return dayjs(dayjs(value), 'MM-DD-YYYY');
  }
  return undefined;
});
