
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class CibButton extends Vue {
  @Prop() color: string;

  @Prop() styles: string;

  @Prop({ default: false }) disabled: boolean;

  getStyles() {
    if (!this.disabled) {
      return `w-full flex items-center justify-center px-4 py-2
            border border-transparent
            text-base leading-6 font-thin
            rounded text-white bg-${this.color}-600 hover:bg-${this.color}-500
            focus:outline-none focus:border-${this.color}-700 focus:shadow-
            outline-${this.color} transition duration-150 ease-in-out md:py-42
            md:text-lg ${this.styles}`;
    }
    return `w-full flex items-center justify-center px-4 py-2
       border border-transparent
       text-base leading-6 font-thin
       rounded text-white bg-gray-400 cursor-not-allowed opacity-50`;
  }
}
