



















export default {
  name: 'VxInputGroup',
  props: {
    prependClasses: { type: String, default: () => '' },
    appendClasses: { type: String, default: () => '' },
  },
};
