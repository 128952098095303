
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import dayjs from '@/app/utils/date';
import isEmpty from '../../app/utils/object.utils';

@Component
export default class Datepicker extends Vue {
  @Prop() context!: any;

  selectedIndex = 0;

  @Watch('model')
  handleModel() {
    this.selectedIndex = 0;
  }

  get model() {
    return this.context.model;
  }

  set model(date: any) {
    this.context.model = this.formatDates(date);
  }

  get minDate() {
    return dayjs().format('MM-DD-YYYY');
  }

  get format() {
    if (isEmpty(this.model[this.context.name])) {
      return '';
    }
    if (this.mode === 'single') {
      return `${dayjs(this.model[this.context.name]).format('MMM DD')}`;
    }
    return `${dayjs(this.model[this.context.name]).format('MMM DD')} - ${dayjs(this.model.checkOutDate).format('MMM DD, YYYY')}`;
  }

  formatDates(date: Date) {
    return dayjs(date).format('MM-DD-YYYY');
  }

  currentDate() {
    return dayjs(new Date());
  }

  get mode() {
    if (this.context.attributes.range) {
      return 'single';
    }
    return 'range';
  }
}
