import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import service from '@/services/app-http-client';
import contentModule from '@/store/modules/content.module';
import store from '@/store';
import dayjs from '@/app/utils/date';
import userModule from '@/store/modules/user/user.module';
import isEmpty from '@/app/utils/object.utils';
import { ITenantDto, IBookingDto, ISubscriptionDto } from '../../../app/entities';
import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';
import { UserDto } from '../../../app/models/user/user.dto';
import PaymentsDto from '../../../app/entities/subscription/IPaymentDto';

@Module({
  dynamic: true,
  store,
  name: 'tenant',
  namespaced: true,
  preserveState: false,
})
class TenantModule extends VuexModule {
  _vm!: any;

  public tenant: ITenantDto = {} as ITenantDto;

  public currentSubscription: ISubscriptionDto = {} as ISubscriptionDto;

  public accommodations: AccommodationDto[] = [];

  public payments: PaymentsDto[] = [] as PaymentsDto[];

  public paymentData: any = {};

  public members: UserDto[] = [];

  public keys: string[] | string = [];

  public dashboardMetrics: any = [];

  public showPricing = false;

  public accommodation: AccommodationDto[] = [];

  public index = 0;

  get billing() {
    return this.tenant.billing;
  }

  get hasRegistered() {
    return !!this.tenant;
  }

  get subExpired() {
    if (this.tenant.subscription?.isActive && dayjs(this.tenant.subscription.currentPeriodEnd).isBefore(dayjs(new Date()))) {
      return true;
    }
    return false;
  }

  get accommodationList(): Record<string, string>[] {
    return this.accommodations.map((acc) => {
      return { name: acc.name as string, value: acc.id as string };
    });
  }

  get apiKey() {
    if (isEmpty(this.keys) && isEmpty(this.tenant)) {
      return null;
    }

    if (!isEmpty(userModule.member)) {
      return userModule.member[0].tenantId;
    }
    return isEmpty(this.keys) ? this.tenant.id : this.keys;
  }

  get accommodationName() {
    const mapped: Record<string, any> = {};
    this.accommodations.forEach((element) => {
      mapped[`${element.id}`] = element.name;
    });
    return mapped;
  }

  get bookingMap() {
    return this.accommodations?.flatMap((acc: any) => acc.booking);
  }

  get checkins() {
    return this.tenant?.subscription?.checkIns;
  }

  // (, { fields: { name: this.organisation?.subscription?.subType } }
  get subscription() {
    return contentModule.subContent.find((val: any) => {
      /*       console.log(val);
       */ return val.fields.name === this.tenant?.subscription?.plan;
    });
  }

  get tenantInvites() {
    return `${this.tenant?.subscription?.userInvites} / ${this.tenant?.subscription?.plan?.userInvites}`;
  }

  get subExpiry() {
    return dayjs(this.tenant?.subscription?.currentPeriodEnd, 'DD-MM-YYYY');
  }

  @Mutation
  setTenant(organisation: any) {
    this.tenant = organisation;
  }

  @Mutation
  commitMember(member: any) {
    this.members.push(member);
  }

  @MutationAction({ mutate: ['keys'] })
  async setTenantId(keys: string[] | string) {
    return {
      keys,
    };
  }

  @Mutation
  addOneAccommodation(accommodation: Partial<AccommodationDto>) {
    // eslint-disable-next-line no-unused-expressions
    userModule.user?.tenant?.accommodation?.push(accommodation as AccommodationDto);
  }

  @MutationAction({ mutate: ['tenant'] })
  async getTenant() {
    const response = await service.get('tenant');

    const { tenant } = response.data;
    return {
      tenant,
    };
  }

  @MutationAction({ mutate: ['dashboardMetrics'] })
  async setMetrics(payload: any) {
    return {
      dashboardMetrics: payload,
    };
  }

  @MutationAction({ mutate: ['accommodations'] })
  async getAccommodations(params?: Record<string, string>[]) {
    let accommodations: Partial<AccommodationDto>[] = [];
    const response = await service.get(`accommodation`, { params });

    if (response.data.status !== 400) {
      accommodations = response.data;
    }
    return {
      accommodations,
    };
  }

  @MutationAction({ mutate: ['showPricing'] })
  async setShowPricing(show: boolean) {
    return {
      showPricing: show,
    };
  }

  @Action
  async updateAccommodation(accommodation: AccommodationDto) {
    const index = userModule.user?.tenant?.accommodation?.findIndex((x: { id: string | undefined }) => x.id === accommodation.id) as number;

    this.accommodations[index] = accommodation;
    const acc = {
      accommodation,
    };
    return acc;
  }

  @Action
  public async createAccommodation(payload: AccommodationDto) {
    const { data } = await service.post('accommodation', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (!data) {
      throw new Error('Failed to create accommodation');
    }

    this.addOneAccommodation(data);
    return data;
  }

  @Action
  public async createTenant(payload: Partial<ITenantDto>) {
    try {
      const { data } = await service.post('tenant', payload, {
        params: {
          userRole: 'tenant',
        },
      });

      this.setTenant(data);
      store.commit('user/setMember', data.member, { root: true });

      return data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  @MutationAction({ mutate: ['tenant'] })
  public async updateTenant(payload: Partial<ITenantDto>) {
    const { data } = await service.patch(`tenant/${payload.id}`, payload, {
      showNotify: true,
      notify: {
        title: 'Updated',
        text: 'Organisation profile updated.',
      },
    });
    const tenant: Partial<ITenantDto> = data;
    return {
      tenant,
    };
  }

  @Mutation
  public saveBooking(booking: IBookingDto) {
    const index = this.accommodations.findIndex((x) => x.id === booking.accommodationId);

    // eslint-disable-next-line no-unused-expressions
    this.accommodations[index]?.booking?.push(booking);
  }

  @MutationAction({ mutate: ['paymentData'] })
  public async getPaymentData(subscriptionType: string) {
    const res = await service.get(`billing/subscription/${subscriptionType}`);
    return {
      paymentData: res.data,
    };
  }

  @MutationAction({ mutate: ['payments'] })
  public async getPayments(id: string) {
    /*     console.log(id);
     */ const res = await service.get(`billing/payments/${id}`);
    return {
      payments: res.data,
    };
  }

  @MutationAction({ mutate: ['members'] })
  public async findMembers() {
    const res = await service.get('members');
    return {
      members: res.data.user,
    };
  }

  @Action
  public async createMember(memberDto: Record<string, any>) {
    const res = await service.post('members', memberDto);

    if (res.data) {
      this.commitMember(res.data);
    }
    return res.data;
  }

  @Action
  public async deleteAccommodation(id: string) {
    const res = await service.delete(`accommodation/${id}`);
    if (res.data) {
      this.commitMember(res.data);
    }
    return res.data;
  }

  @Action
  public async accommodationQuery(id: string) {
    const { data } = await service.get(`booking/${this.tenant.id}/status/query`, { params: { id } });

    if (data) {
      return data;
    }
    // if (!isEmpty(data.bookingTotals)) {
  }
}

export const tenantModule: TenantModule = getModule(TenantModule, store);
