/* eslint-disable no-param-reassign */
import { AuthenticationResult } from '@azure/msal-browser';
import { Auth } from '@/store/modules/auth.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import userModule from '@/store/modules/user/user.module';
import { buildAbilityFor } from '../casl/ability';
import VueSocketIo from '../websockets';
import emitter from './emitter';
import EventBus from './notification/app.events';
import { AUTH_ERROR, AUTH_SUCCESS, USER_NOT_REGISTERED } from './notification/notification.constants';

export default {
  install(Vue: any) {
    Vue.prototype.$emitter = emitter;
    Vue.use(EventBus);

    emitter.on<any>('loginEvent', async (authResponse: AuthenticationResult) => {
      if (!authResponse) {
        emitter.emit('error', AUTH_ERROR);
      }

      await Auth.login(authResponse);

      if (!userModule.user) {
        throw new Error('No user');
      }
      userModule.setMember(userModule.user.member);

      if (!userModule.user.member) {
        const tokenClaims = authResponse?.account?.idTokenClaims;

        emitter.emit(`registerAuthenticated`, tokenClaims);
      }
      const [member] = userModule.member;

      try {
        tenantModule.setTenant(member.tenant);
      } catch (error: any) {
        emitter.emit('toast', USER_NOT_REGISTERED);
      }

      buildAbilityFor(member.role.ability as any);
      Vue.prototype.$ability.update(member.role.ability as any);

      const idTokenClaim = authResponse?.account?.idTokenClaims;
      Vue.use(VueSocketIo);
      emitter.emit(`${authResponse.state}Authenticated`, idTokenClaim);
    });
    emitter.on<any>('registerEvent', async (authResponse: AuthenticationResult) => {
      if (!authResponse) {
        emitter.emit('error', AUTH_ERROR);
      }

      await Auth.login(authResponse);

      const idTokenClaim = authResponse?.account?.idTokenClaims;

      emitter.emit(`${authResponse.state}Authenticated`, idTokenClaim);
    });
  },
};
