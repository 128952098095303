/* eslint-disable no-param-reassign */
import axios from 'axios';
import Component from 'vue-class-component';
import { setupInterceptorsTo } from './axios.interceptors';
// Register the router hooks with their names
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

const service = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/v1/api`,
  timeout: 300000,
  withCredentials: true,
});
setupInterceptorsTo(service);

export default service;
