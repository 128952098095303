// Toast Notification Component Styles
import '@/styles/vendor/components/toastification.scss';
import Vue from 'vue';
import Toast, { POSITION } from 'vue-toastification';

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 20000,
  transition: 'Vue-Toastification__fade',
  position: POSITION.TOP_RIGHT,
});
