
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class MarketingHeader extends Vue {
  @Prop() content: any;

  showMobileMenu = false;

  showDropdown = false;

  async signIn(state: string) {
    await this.$msal.signIn({ state });
  }
}
