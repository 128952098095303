/* eslint-disable no-param-reassign */
/* eslint-disable radix */
export default {
  darken(color: string, percent: number) {
    const f = color.split(',');
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = parseInt(f[0].slice(4));
    const G = parseInt(f[1]);
    const B = parseInt(f[2]);
    return `rgb(${Math.round((t - R) * p) + R},${Math.round((t - G) * p) + G},${Math.round((t - B) * p) + B})`;
  },
  getColor(colorx: string, alphax = 1, defaultx = true) {
    // change color hex to RGB
    if (/^[#]/.test(colorx)) {
      const c = this.hexToRgb(colorx);

      colorx = alphax === 1 ? `rgb(${c?.r},${c?.g},${c?.b})` : `rgba(${c?.r},${c?.g},${c?.b},${alphax})`;
    } else if (/^rgba/.test(colorx)) {
      if (colorx.search(/.([0-9]\))$/) === -1 && !defaultx) {
        colorx = colorx.replace(/.?([0-9]\))$/, `${alphax})`);
      }
    } else if (/^(rgb)/.test(colorx)) {
      // change rgb and rgba
      if (alphax !== 1) {
        colorx = colorx.replace(/^(rgb)/, `rgba`);
        colorx = colorx.replace(/\)$/, `,${alphax})`);
      }
    }
    return colorx;
  },
  isColor(colorx: string) {
    const vscolors = ['primary', 'secondary', 'success', 'danger', 'warning', 'dark', 'light'];
    return vscolors.includes(colorx);
  },
  RandomColor() {
    function getRandomInt(min: number, max: number) {
      return Math.floor(Math.random() * (max - min)) + min;
    }
    return `rgb(${getRandomInt(0, 255)},${getRandomInt(0, 255)},${getRandomInt(0, 255)})`;
  },
  rColor(colorx: string, opacity = 1) {
    if (/^[#]/.test(colorx)) {
      const c = this.hexToRgb(colorx);
      colorx = `rgba(${c?.r},${c?.g},${c?.b},${opacity})`;
    } else if (/^[rgb]/.test(colorx)) {
      let colorSplit = colorx.split(')')[0];
      if (!/^[rgba]/.test(colorx)) {
        colorSplit.replace('rgb', 'rgba');
        colorSplit += `,${opacity})`;
      } else {
        // colorSplit.replace('rgb','rgba')
        colorSplit += `)`;
      }
      colorx = colorSplit;
    }

    const vscolors = ['primary', 'success', 'danger', 'warning', 'dark'];
    if (colorx) {
      if (/[#()]/.test(colorx)) {
        return colorx;
      }
      if (vscolors.includes(colorx)) {
        return `rgba(var(--${colorx}),${opacity})`;
      }
      return `rgba(var(--primary),${opacity})`;
    }
    return `rgba(var(--primary),${opacity})`;
  },
  contrastColor(elementx: any) {
    let c = elementx;
    if (/[#]/g.test(elementx)) {
      const rgbx = this.hexToRgb(elementx);
      c = `rgb(${rgbx?.r},${rgbx?.g},${rgbx?.b})`;
    }
    const rgb = c
      .replace(/^(rgb|rgba)\(/, '')
      .replace(/\)$/, '')
      .replace(/\s/g, '')
      .split(',');
    const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    if (yiq >= 128) {
      return true;
    }
    return false;
  },
  setCssVariable(propertyName: string, value: string | null) {
    if (typeof window !== 'undefined') {
      document.documentElement.style.setProperty(propertyName, value);
    }
  },
  hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (r: any, g: any, b: any) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  },
  getVariable(styles: CSSStyleDeclaration, propertyName: string) {
    return String(styles.getPropertyValue(propertyName)).trim();
  },
  changeColor(colorInicial: string) {
    const colores = ['primary', 'success', 'danger', 'warning', 'dark'];
    let colorx;

    if (colores.includes(colorInicial)) {
      const style = getComputedStyle(document.documentElement);
      colorx = this.getVariable(style, `--${colorInicial}`);
    } else if (/[rgb()]/g.test(colorInicial)) {
      colorx = colorInicial.replace(/[rgb()]/g, '');
    } else if (/[#]/g.test(colorInicial)) {
      const rgbx = this.hexToRgb(colorInicial);
      colorx = `${rgbx?.r},${rgbx?.g},${rgbx?.b}`;
    } else {
      colorx = `--${colorInicial}`;
    }
    return colorx;
    // this.setCssVariable('--'+clave,colorx)
  },
  isColors(color: string) {
    const vsColors = [
      'primary',
      'secondary',
      'success',
      'danger',
      'warning',
      'dark',
      'light',
      'warn',
      // social colors
      'facebook',
      'twitter',
      'youtube',
      'pinterest',
      'linkedin',
      'snapchat',
      'whatsapp',
      'tumblr',
      'reddit',
      'spotify',
      'amazon',
      'medium',
      'vimeo',
      'skype',
      'dribbble',
      'slack',
      'yahoo',
      'twitch',
      'discord',
      'telegram',
      'google-plus',
      'messenger',
    ];
    return vsColors.includes(color);
  },
  setColor(colorName: string, color: string, el: any, addClass?: boolean) {
    function hexToRgb(hex: string) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (_m: any, r: string, g: string, b: string) => {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            // tslint:disable-next-line:object-literal-sort-keys
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    }

    const isRGB = /^(rgb|rgba)/.test(color);
    const isRGBNumbers =
      /^(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)$/.test(color);
    const isHEX = /^(#)/.test(color);
    let newColor;

    if (color === 'dark' && el) {
      if (addClass) {
        el.classList.add('vs-component-dark');
      }
    }

    if (isRGB) {
      const arrayColor = color.replace(/[rgba()]/g, '').split(',');
      newColor = `${arrayColor[0]},${arrayColor[1]},${arrayColor[2]}`;
      this.setVar(colorName, newColor, el);
      if (addClass) {
        el.classList.add('vs-change-color');
      }
    } else if (isHEX) {
      const rgb = hexToRgb(color);
      newColor = `${rgb!.r},${rgb!.g},${rgb!.b}`;
      this.setVar(colorName, newColor, el);
      if (addClass) {
        el.classList.add('vs-change-color');
      }
    } else if (this.isColor(color)) {
      const style = window.getComputedStyle(document.body);
      newColor = style.getPropertyValue(`--vs-${color}`);
      this.setVar(colorName, newColor, el);
      if (addClass) {
        el.classList.add('vs-change-color');
      }
    } else if (isRGBNumbers) {
      this.setVar(colorName, color, el);
      if (addClass) {
        el.classList.add('vs-change-color');
      }
    } else {
      //     consolee.warn({
      //       el,
      //       link: 'https://lusaxweb.github.io/vuesax/',
      //       text: `• Component: ${el.__vue__.$vnode.componentOptions.tag}
      // • Warn info: El formato de la propiedad color es incorrecto
      // • Prop: color
      // • value: ${color}
      // • Valores Permitidos: (RGB, HEX, RGB Numbers, Vuesax Colors)
      // • Example: color="#000" or color="rgb(255,255,255)"`,
      //       title: 'VUESAX'
      //     })
    }
  },
  setVar(propertyName: string, value: string, el: any) {
    if (!el) {
      document.documentElement.style.setProperty(`--vs-${propertyName}`, value);
    } else if (el.nodeName !== '#comment') {
      el.style.setProperty(`--vs-${propertyName}`, value);
    }
  },
};
