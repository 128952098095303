import b2cPolicies from './policy.config';
import { loginRequest, tokenRequest } from './request.config';

const msalConfig = {
  auth: {
    clientId: 'a7155f6d-c0a5-4d0d-aa29-e7900033c1d5',
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: `${process.env.VUE_APP_URL}`,
    requireAuthOnInitialize: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  loginRequest,
  tokenRequest,
  resetRequest: b2cPolicies.authorities.forgotPassword,
  framework: {
    globalMixin: true,
  },
};

export default msalConfig;
