<template>
  <div class="toastification">
    <div class="flex items-start">
      <!-- <i :variant="variant" size="1.8rem" class="mr-2 flex-shrink-0">
        <feather-icon :icon="icon" size="15" />
      </i> -->
      <font-awesome-icon size="sm" :icon="icon" class="stroke-current w-5 h-5 mr-2 mt-1" :class="`text-${variant}`" />

      <div class="flex flex-grow-0">
        <div>
          <h5 v-if="title" class="mb-0 font-bold toastification-title" :class="`text-${variant}`" v-text="title" />
          <small v-if="text" class="inline-block text-body" v-text="text" />
        </div>
        <span class="cursor-pointer toastification-close-icon ml-auto" @click="$emit('close-toast')">
          <feather-icon v-if="!hideClose" icon="XIcon" class="text-body" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
