<script>
/* eslint-disable no-plusplus */
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
  components: {},
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    modelData: {
      get() {
        // if (this.context.model.route !== undefined) {
        //   return `${this.context.model.street_number}, ${this.context.model.route}, ${this.context.model.locality}, ${this.context.model.country}, ${this.context.model.postal_code}`;
        // }
        return this.context.model;
      },
      // setter
      set(newValue) {
        // eslint-disable-next-line vue/no-mutating-props
        // eslint-disable-next-line vue/no-mutating-props
        this.context.model = newValue.formatted_address;
      },
    },
  },
  watch: {
    model() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    getAddressData(addressData) {
      this.context.rootEmit(addressData);

      this.modelData = addressData;
    },
  },
};
</script>

<template>
  <div :class="`formulate-input-element formulate-input-element--${context.type} datepicker-trigger`" :data-type="context.type">
    <form autocomplete="off">
      <gmap-autocomplete
        class="introInput"
        :ref="context.attributes.ref"
        v-model="modelData"
        classname="form-control"
        v-on:place_changed="getAddressData"
      >
        <template v-slot:input="slotProps">
          <v-text-field
            outlined
            prepend-inner-icon="place"
            placeholder="Location Of Event"
            ref="input"
            v-on:listeners="slotProps.listeners"
            v-on:attrs="slotProps.attrs"
          >
          </v-text-field>
        </template>
      </gmap-autocomplete>
      <!-- <vue-google-autocomplete
        :id="context.name"
        :ref="context.attributes.ref"
        v-model="modelData"
        classname="form-control"
        :placeholder="context.attributes.placeholder"
        :types="context.attributes.types"
        :country="context.attributes.country"
        @placechanged="getAddressData"
      >
      </vue-google-autocomplete> -->
    </form>
  </div>
</template>

<style lang="scss">
.pac-container {
  z-index: 9999;
  .pac-item {
    z-index: 9999;
  }
}
</style>
