import { dom, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faArrowUp,
  faArrowDown,
  faBars,
  faBell,
  faBriefcase,
  faCalendarAlt,
  faChartLine,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChild,
  faCircleNotch,
  faCog,
  faConciergeBell,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faFileSignature,
  faFilePdf,
  faHome,
  faHotel,
  faLock,
  faTrash,
  faMapMarkerAlt,
  faMinusCircle,
  faMoon,
  faNotesMedical,
  faPaperPlane,
  faPlus,
  faCheck,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faSeedling,
  faUndo,
  faShieldVirus,
  faSignOutAlt,
  faFileInvoice,
  faStar,
  faStarOfLife,
  faStethoscope,
  faTimes,
  faUpload,
  faUser,
  faUsers,
  faUserFriends,
  faSync,
  faBookmark,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
  faAngleDown,
  faAngleUp,
  faArrowUp,
  faArrowDown,
  faBars,
  faBell,
  faBookmark,
  faBriefcase,
  faCalendarAlt,
  faChartLine,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChild,
  faCircleNotch,
  faCog,
  faConciergeBell,
  faFileInvoice,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faFilePdf,
  faHome,
  faHotel,
  faLock,
  faTrash,
  faMapMarkerAlt,
  faMinusCircle,
  faMoon,
  faNotesMedical,
  faCheck,
  faPaperPlane,
  faPaperclip,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faSeedling,
  faShieldVirus,
  faSignOutAlt,
  faStar,
  faStarOfLife,
  faStethoscope,
  faSync,
  faTimes,
  faUpload,
  faUser,
  faUsers,
  faUserFriends,
  faUndo,
  faFileSignature,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

dom.watch();
