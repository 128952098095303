// eslint-disable-next-line no-shadow
enum RoleType {
  Public = 'public',
  Guest = 'guest',
  organisation = 'organisation',
  Admin = 'admin',
  Accommodation = 'accommodation',
}

export default RoleType;
